import React, { useCallback, useContext, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { MdEdit, MdOutlineDelete, MdOutlineQuiz } from "react-icons/md";
import {
  IoMdAdd,
  IoIosCloseCircleOutline,
  IoIosArrowBack,
  IoIosArrowForward,
} from "react-icons/io";
import { MdDelete } from "react-icons/md";
import { GrUpdate } from "react-icons/gr";

import "../../../../index.css";
import { DarkModeContext } from "../../../../Context/DarkModeContext.jsx";
import axiosInstance from "../../../../Context/axiosInstance.js";

export default function ContentList() {
  const { isDarkMode } = useContext(DarkModeContext);
  const navigate = useNavigate();
  const location = useLocation();
  const { state } = location;
  const courseName = state?.courseName;

  const [contents, setContents] = useState([]);
  const [selectedContents, setSelectedContents] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(20);
  const [totalPages, setTotalPages] = useState(1);

  //states for add modal
  const [newCatalogData, setNewCatalogData] = useState([
    {
      title: "",
      description: "",
      url: "",
      price: 0,
    },
  ]);

  const [isAddModalOpen, setIsAddModalOpen] = useState(false);
  const [animationClass, setAnimationClass] = useState("");

  // State for editing mode and selected course
  const [isEditModalOpen, setEditModalOpen] = useState(false);
  const [editContentData, setEditContentData] = useState({
    id: "",
    title: "",
    description: "",
    url: "",
    price: 0,
  });

  // State for deleting mode and selected course
  const [confirmDeleteModal, setConfirmDeleteModal] = useState(null);
  const [deleting, setDeleting] = useState(false);

  //Form Data
  const [loadingAddContent, setLoadingAddContent] = useState("");
  const [errors, setErrors] = useState([]);

  // New state variables for sorting
  const [searchQuery, setSearchQuery] = useState("");
  const [sortColumn, setSortColumn] = useState("Id");
  const [sortDirection, setSortDirection] = useState("desc");

  useEffect(() => {
    // Reset to page 1 whenever the search query changes
    setPage(1);
  }, [searchQuery]);

  const fetchCatalogData = useCallback(async () => {
    setLoading(true);
    setError("");

    try {
      const response = await axiosInstance.get(
        `/catalogs/quizzes?page=${page}&pageSize=${pageSize}&sortColumn=${sortColumn}&sortDirection=${sortDirection}&search=${encodeURIComponent(
          searchQuery
        )}`
      );

      setContents(response.data.response);
      setLoading(false);
      setTotalPages(Math.ceil(response.data.totalCount / pageSize));
    } catch (error) {
      if (error.response) {
        if (error.response.status === 401) {
          // Unauthorized error (token expired or invalid)
        } else {
          setError(
            error.response.data.message || "An unexpected error occurred."
          );
        }
      } else if (error.request) {
        setError("No response received from server.");
      } else {
        setError(error.message || "An unexpected error occurred.");
      }
    }
  }, [page, pageSize, searchQuery, sortColumn, sortDirection]);

  useEffect(() => {
    fetchCatalogData();
  }, [fetchCatalogData]);

  //handle changing of table page not main page
  const handlePageChange = (newPage) => {
    if (newPage >= 1 && newPage <= totalPages) {
      setPage(newPage);
    }
  };

  const openAddModal = () => {
    setAnimationClass("slide-in");
    setIsAddModalOpen(true);
    setError("");
  };

  const closeAddModal = () => {
    setIsAddModalOpen(false);
    setNewCatalogData([
      {
        title: "",
        description: "",
        url: "",
        price: 0,
      },
    ]);
    setErrors([]);
  };

  const handleFormChange = (index, e) => {
    const { name, value } = e.target;
    const updatedCatalogData = [...newCatalogData]; // Create a copy of the array

    updatedCatalogData[index] = {
      ...updatedCatalogData[index], // Keep other properties the same
      [name]: value, // Update the specific field
    };

    setNewCatalogData(updatedCatalogData); // Update the state with the new array
  };

  // Handle Create Catalog (POST)
  const handleCreateCatalog = async (newCatalogData) => {
    setLoading(true);
    setLoadingAddContent(true);

    // Clear previous errors before making the API request
    setErrors([]);

    try {
      const postBody = {
        title: newCatalogData.title,
        description: newCatalogData.description,
        url: newCatalogData.url,
        price: newCatalogData.price,
      };

      const response = await axiosInstance.post("/catalogs/quiz", postBody);

      // Add the new catalog to the list
      setContents((prevCatalogs) => [...prevCatalogs, response.data]);

      // Reset form state
      setNewCatalogData([
        {
          title: "",
          description: "",
          url: "",
          price: 0,
        },
      ]);

      await fetchCatalogData();
      closeAddModal();
    } catch (error) {
      if (error.response) {
        if (
          error.response?.data?.errors &&
          Array.isArray(error.response.data.errors)
        ) {
          const serverErrors = error.response.data.errors;

          // Convert error array into an object to display in form
          const errorObject = serverErrors.reduce((acc, curr) => {
            if (curr.name && curr.reason) {
              acc[curr.name] = curr.reason; // Use the field name as the key and reason as the value
            }
            return acc;
          }, {});

          setErrors(errorObject); // Set the errors object in state
        } else if (error.response?.data?.message) {
          setErrors({
            general: error.response.data.message,
          });
        } else {
          setErrors({
            general: "An unexpected error occurred while creating the catalog.",
          });
        }
      } else if (error.request) {
        setErrors({
          general:
            "No response from the server. Please check your internet connection.",
        });
      } else {
        setErrors({
          general: `An error occurred: ${error.message}`,
        });
      }
    } finally {
      setLoading(false);
      setLoadingAddContent(false);
    }
  };

  // Function to close the modal
  const closeEditModal = () => {
    setEditModalOpen(false);
    setEditContentData({
      title: "",
      description: "",
      url: "",
      price: 0,
    });
    setErrors("");
  };

  // This will handle form input changes for editing content
  const handleEditFormChange = (e) => {
    const { id, value } = e.target;
    setEditContentData((prevData) => ({ ...prevData, [id]: value }));
  };

  // Handle Edit Catalog (PUT)
  const handleEditCatalog = async (editedCatalogData) => {
    setLoading(true);
    setErrors([]); // Clear previous errors before sending request

    try {
      const putBody = {
        title: editedCatalogData.title,
        description: editedCatalogData.description,
        url: editedCatalogData.url,
        price: editedCatalogData.price,
      };

      const response = await axiosInstance.put(
        `/catalogs/quiz/${editContentData.id}`,
        putBody
      );

      // Update the catalog in the list
      setContents((prevCatalogs) =>
        prevCatalogs.map((catalog) =>
          catalog.id === editContentData.id ? response.data : catalog
        )
      );

      // Reset form state
      setContents({ title: "", description: "", url: "", price: 0 });
      setErrors([]); // Clear any previous errors
      closeEditModal();
      await fetchCatalogData();
    } catch (error) {
      if (error.response) {
        if (
          error.response?.data?.errors &&
          Array.isArray(error.response.data.errors)
        ) {
          const serverErrors = error.response.data.errors;

          // Convert error array into an object to display in form
          const errorObject = serverErrors.reduce((acc, curr) => {
            if (curr.name && curr.reason) {
              acc[curr.name] = curr.reason; // Use the field name as the key and reason as the value
            }
            return acc;
          }, {});

          setErrors(errorObject); // Set the errors object in state
        } else if (error.response?.data?.message) {
          setErrors({
            general: error.response.data.message,
          });
        } else {
          setErrors({
            general: "An unexpected error occurred while creating the catalog.",
          });
        }
      } else if (error.request) {
        setErrors({
          general:
            "No response from the server. Please check your internet connection.",
        });
      } else {
        setErrors({
          general: `An error occurred: ${error.message}`,
        });
      }
    } finally {
      setLoading(false);
      setSelectedContents([]);
    }
  };

  // Handle Delete Catalog (DELETE)
  const handleDeleteCatalog = async (videoIds) => {
    setDeleting(true);
    setLoading(true);

    try {
      // Deleting multiple catalogs at once with handling retries or server confirmation
      const deletePromises = videoIds.map((videoId) => {
        return axiosInstance
          .delete(`/catalogs/quiz/${videoId}`)
          .catch((error) => {
            // Handle each failed deletion and log it separately for better tracking
            console.error(
              `Failed to delete catalog with ID: ${videoId}`,
              error
            );
            return null; // Continue processing other deletions
          });
      });

      const results = await Promise.all(deletePromises);

      // Filter out null results (failed deletions) and confirm deletions were successful
      const successfulDeletes = results.filter((result) => result !== null);

      if (successfulDeletes.length > 0) {
        // Remove the deleted catalogs from the list
        setContents((prevCatalogs) =>
          prevCatalogs.filter((catalog) => !videoIds.includes(catalog.id))
        );

        // Refresh catalog data (consider waiting for confirmation if needed)
        await fetchCatalogData();
      } else {
        setErrors(["An issue occurred while deleting catalogs."]);
      }

      setDeleting(false);
      setConfirmDeleteModal(false);
    } catch (error) {
      const serverErrors = error.response?.data?.errors || [
        "An unexpected error occurred while deleting the catalogs.",
      ];
      setErrors(serverErrors);
    } finally {
      setLoading(false);
      setDeleting(false);
      setSelectedContents([]);
    }
  };

  //this will control selected content to be able to edit or delete
  const handleSelectContent = (videoId) => {
    setSelectedContents((prevSelected) =>
      prevSelected.includes(videoId)
        ? prevSelected.filter((id) => id !== videoId)
        : [...prevSelected, videoId]
    );
  };

  // control sorting direction
  const toggleSortDirection = (column) => {
    if (sortColumn === column) {
      setSortDirection((prev) => (prev === "asc" ? "desc" : "asc"));
    } else {
      setSortColumn(column);
      setSortDirection("asc");
    }
  };

  // Format path to include the section name
  // Split the path into segments
  const pathSegments = location.pathname
    .slice(1) // Remove leading slash
    .split("/"); // Split by slashes

  // Format each segment
  const formattedSegments = pathSegments.map(
    (segment) =>
      segment
        .replace(/[_-]/g, " ") // Replace underscores or hyphens with spaces
        .replace(/\b\w/g, (char) => char.toUpperCase()) // Capitalize first letter of each word
  );

  // Insert courseName in the middle of the path
  if (courseName) {
    // Determine the middle index
    const middleIndex = Math.floor(formattedSegments.length / 2);
    formattedSegments.splice(
      middleIndex,
      1,
      courseName.charAt(0).toUpperCase() + courseName.slice(1)
    );
  }

  // Join segments with " > " separator
  const formattedPath = formattedSegments.join(" > ");

  // Handlers for pagination
  const goToNextPage = () => {
    navigate(+1);
  };

  const goToPreviousPage = () => {
    navigate(-1);
  };

  return (
    <div
      className={`flex flex-col lg:flex-row lg:items-start ${
        isDarkMode ? "bg-[#292929] text-black" : "bg-white text-black"
      } p-4 lg:p-8 min-h-screen`}
    >
      <div
        className={`${
          isDarkMode ? "bg-[#1e1e1e] text-white" : "bg-white text-black"
        } p-4 lg:p-8 mt-[40px] lg:mt-0 rounded-lg shadow-xl flex-1`}
      >
        {/* PATH AND CONTROLS */}
        <div className="lg:flex w-full items-center mb-4 text-gray-700 text-sm capitalize">
          <div className="flex w-[58px] gap-1">
            <button
              onClick={goToPreviousPage}
              className="w-[24px] h-[20px] flex justify-center items-center bg-gray-300 rounded-[6px] disabled:opacity-50"
            >
              <IoIosArrowBack />
            </button>
            <button
              onClick={goToNextPage}
              className="w-[24px] h-[20px] flex justify-center items-center bg-gray-300 rounded-[6px] disabled:opacity-50"
            >
              <IoIosArrowForward />
            </button>
          </div>

          {/* Display current path */}
          <div
            className={`${
              isDarkMode ? "text-white" : "text-black"
            } flex items-center text-sm capitalize p-2`}
          >
            <span>{formattedPath}</span>
          </div>
        </div>

        {/*/////////////// EDIT MODAL//////////////////////// */}
        {isEditModalOpen && (
          <div className="fixed inset-0 flex items-center justify-center bg-gray-900 bg-opacity-50 z-50">
            <div
              className={`relative ${animationClass} flex flex-col ${
                isDarkMode ? "bg-white text-black" : "bg-white text-black"
              } p-6 rounded-lg shadow-lg w-80`}
            >
              <IoIosCloseCircleOutline
                onClick={closeEditModal}
                className="absolute top-4 right-4 text-2xl cursor-pointer text-gray-500 hover:text-red-500"
              />
              <h2 className="text-2xl font-bold text-center mb-4">Edit Quiz</h2>
              <form className="flex flex-col gap-4">
                <input
                  type="text"
                  id="title"
                  name="title"
                  placeholder="Quiz Title"
                  value={editContentData.title}
                  onChange={handleEditFormChange}
                  className="px-3 py-2 rounded-md border border-gray-300"
                />
                {/* Display error message */}
                {errors.title && (
                  <p className="text-red-500 text-sm mt-1">{errors.title}</p>
                )}

                <input
                  type="text"
                  id="description"
                  name="description"
                  placeholder="Quiz Description"
                  value={editContentData.description}
                  onChange={handleEditFormChange}
                  className="px-3 py-2 rounded-md border border-gray-300"
                />
                {/* Display error message */}
                {errors.description && (
                  <p className="text-red-500 text-sm mt-1">
                    {errors.description}
                  </p>
                )}

                <input
                  type="text"
                  id="url"
                  name="url"
                  placeholder="Quiz URL"
                  value={editContentData.url}
                  onChange={handleEditFormChange}
                  className="px-3 py-2 rounded-md border border-gray-300"
                />
                {/* Display error message */}
                {errors.url && (
                  <p className="text-red-500 text-sm mt-1">{errors.url}</p>
                )}

                <input
                  type="text"
                  id="price"
                  name="price"
                  placeholder="Quiz Price"
                  value={editContentData.price}
                  onChange={handleEditFormChange}
                  className="px-3 py-2 rounded-md border border-gray-300"
                />
                {/* Display error message */}
                {errors.price && (
                  <p className="text-red-500 text-sm mt-1">{errors.price}</p>
                )}
              </form>

              <div className="flex justify-center mt-4">
                <button
                  onClick={() => handleEditCatalog(editContentData)}
                  className="flex items-center text-center text-sm gap-2 text-white rounded-md bg-[#2264E5] hover:bg-sky-500 py-2 px-4 border border-transparent transition-all shadow-sm hover:shadow-lg focus:bg-slate-700 focus:shadow-none  active:shadow-none disabled:pointer-events-none disabled:opacity-50 disabled:shadow-none"
                  type="button"
                >
                  <GrUpdate size={18} />
                  {loadingAddContent ? "Updating..." : "Update"}
                </button>
              </div>
            </div>
          </div>
        )}

        {/*/////////////// ADD MODAL//////////////////////// */}
        {isAddModalOpen && (
          <div className="fixed inset-0 flex items-center justify-center bg-gray-900 bg-opacity-50 z-50">
            <div
              className={`${animationClass} w-[330px] overflow-y-auto relative flex flex-col ${
                isDarkMode ? "bg-white text-black" : "bg-white text-black"
              } p-6 rounded-[35px] shadow-lg w-100 scrollbar-hide`}
            >
              <IoIosCloseCircleOutline
                onClick={closeAddModal}
                className="absolute top-4 right-4 text-2xl cursor-pointer text-gray-500 hover:text-red-500"
              />
              <h2 className="text-2xl font-bold text-center mt-4 mb-4">
                New Entry
              </h2>
              {newCatalogData.map((quiz, index) => (
                <div
                  key={index}
                  className="flex flex-col gap-4 border-b-[1px] border-gray-400 pb-5"
                >
                  <div className="w-[280px]">
                    <form className="flex flex-col gap-2">
                      <div className="relative">
                        <input
                          type="text"
                          name="title"
                          placeholder="Quiz Title"
                          value={quiz.title}
                          onChange={(e) => handleFormChange(index, e)}
                          className="floating-input px-3 py-2 mt-2.5 text-[14px] max-w-full bg-white rounded-xl border border-solid border-black border-opacity-20 w-[314px]"
                          required
                        />
                        {errors.title && (
                          <div className="text-red-500 text-sm mt-1">
                            {errors.title}
                          </div>
                        )}
                      </div>

                      <div className="relative">
                        <input
                          type="text"
                          name="description"
                          placeholder="Quiz Description"
                          value={quiz.description}
                          onChange={(e) => handleFormChange(index, e)}
                          className="floating-input px-3 py-2 mt-2.5 text-[14px] max-w-full bg-white rounded-xl border border-solid border-black border-opacity-20 w-[314px]"
                          required
                        />
                        {errors.description && (
                          <div className="text-red-500 text-sm mt-1">
                            {errors.description}
                          </div>
                        )}
                      </div>

                      <div className="relative">
                        <input
                          type="text"
                          name="url"
                          placeholder="Quiz URL"
                          value={quiz.url}
                          onChange={(e) => handleFormChange(index, e)}
                          className="floating-input px-3 py-2 mt-2.5 text-[14px] max-w-full bg-white rounded-xl border border-solid border-black border-opacity-20 w-[314px]"
                          required
                        />
                        {errors.url && (
                          <div className="text-red-500 text-sm mt-1">
                            {errors.url}
                          </div>
                        )}
                      </div>

                      <div className="relative">
                        <input
                          type="text"
                          name="price"
                          placeholder="Quiz Price"
                          value={quiz.price}
                          onChange={(e) => handleFormChange(index, e)}
                          className="floating-input px-3 py-2 mt-2.5 text-[14px] max-w-full bg-white rounded-xl border border-solid border-black border-opacity-20 w-[314px]"
                          required
                        />
                        {errors.price && (
                          <div className="text-red-500 text-sm mt-1">
                            {errors.price}
                          </div>
                        )}
                      </div>
                    </form>
                  </div>
                </div>
              ))}

              <div className="flex justify-center items-center mt-4">
                <button
                  onClick={() => handleCreateCatalog(newCatalogData[0])}
                  className="flex items-center text-center text-white gap-2 rounded-md bg-[#2264E5] hover:bg-sky-500 py-2 px-4 border border-transparent transition-all shadow-sm hover:shadow-lg focus:bg-slate-700 focus:shadow-none active:shadow-none disabled:pointer-events-none disabled:opacity-50 disabled:shadow-none"
                  type="button"
                >
                  <MdOutlineQuiz size={25} />
                  {loadingAddContent ? "Adding..." : "Add"}
                </button>
              </div>
            </div>
          </div>
        )}

        {/* DELETE MODAL */}
        {confirmDeleteModal && (
          <div className="fixed inset-0 flex items-center justify-center bg-gray-900 bg-opacity-50 z-50">
            <div
              className={`${animationClass} w-full max-w-md h-auto ${
                isDarkMode ? "bg-white text-black" : "bg-white text-black"
              } p-8 rounded-lg shadow-lg`}
            >
              <div className="flex justify-center pb-4">
                <MdDelete className="text-customRed justify-center" size={50} />
              </div>
              <p className="text-lg font-semibold mb-4 text-center">
                Are you sure you want to delete{" "}
                {selectedContents > 1 ? "this quiz?" : "these quizzes?"}
              </p>
              <div className="flex justify-center gap-4">
                <button
                  onClick={() => handleDeleteCatalog(selectedContents)}
                  className="bg-red-600 text-white px-4 py-2 rounded-md hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-red-500"
                >
                  {deleting ? "Deleting..." : "Yes"}
                </button>
                <button
                  onClick={() => setConfirmDeleteModal(false)}
                  className="bg-gray-300 text-gray-800 px-4 py-2 rounded-md hover:bg-gray-400 focus:outline-none focus:ring-2 focus:ring-gray-500"
                >
                  Cancel
                </button>
              </div>
            </div>
          </div>
        )}

        <div className="flex flex-col lg:flex-row lg:justify-between mb-4">
          <div className="flex flex-col lg:flex-row lg:justify-between pb-2">
            <input
              type="text"
              placeholder="Search"
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
              className={`${
                isDarkMode ? "bg-[#292929]" : "bg-gray-100"
              } px-4 py-2 border border-gray-300 rounded-lg`}
            />
          </div>
          <div className="flex flex-col lg:flex-row lg:gap-2">
            <button
              onClick={openAddModal}
              className={`flex items-center mb-2 lg:mb-0 px-4 py-2 lg:px-6 lg:py-3 justify-center bg-[#2264E5] text-white rounded-lg transition-opacity`}
            >
              <IoMdAdd size={30} className="pr-2" />
              Add
            </button>
            <button
              onClick={() => {
                if (selectedContents.length === 1) {
                  const contentToEdit = contents.find(
                    (content) => content.id === selectedContents[0]
                  );
                  setEditContentData(contentToEdit);
                  setEditModalOpen(true);
                }
              }}
              disabled={selectedContents.length === 0}
              className={`flex items-center mb-2 lg:mb-0 px-4 py-2 lg:px-6 lg:py-3 justify-center bg-[#2264E5] text-white rounded-lg transition-opacity ${
                selectedContents.length === 0 || selectedContents.length > 1
                  ? "opacity-40 cursor-not-allowed"
                  : "hover:bg-blue-600"
              }`}
            >
              <MdEdit size={25} className="pr-2" />
              Edit
            </button>
            <button
              onClick={() => {
                setConfirmDeleteModal(true);
              }}
              disabled={selectedContents.length === 0}
              className={`flex items-center mb-2 lg:mb-0 px-4 py-2 lg:px-6 lg:py-3 justify-center bg-red-500 text-white rounded-lg transition-opacity ${
                selectedContents.length === 0
                  ? "opacity-40 cursor-not-allowed"
                  : "hover:bg-red-600"
              }`}
            >
              <MdOutlineDelete size={30} className="pr-2" />
              Delete
            </button>
          </div>
        </div>
        <div className="max-w-[320px] md:max-w-[700px] lg:max-w-full overflow-x-auto pb-4 min-h-[850px]">
          <table className="min-w-full divide-y divide-gray-200">
            <thead
              className={`${
                isDarkMode ? "bg-[#1e1e1e] text-white" : "bg-white text-black"
              }`}
            >
              <tr>
                <th className="px-4 py-2 text-left font-medium text-gray-500 uppercase tracking-wider">
                  <input
                    type="checkbox"
                    onChange={(e) =>
                      setSelectedContents(
                        e.target.checked ? contents.map((quiz) => quiz.id) : []
                      )
                    }
                    checked={selectedContents.length === contents.length}
                  />
                </th>
                <th
                  onClick={() => toggleSortDirection("Title")}
                  className="cursor-pointer px-4 py-2 text-center text-base font-medium text-gray-500 uppercase tracking-wider"
                >
                  Title
                  {sortColumn === "Title" && (
                    <span className="text-sm px-1 items-center text-blue-400">
                      {sortDirection === "asc" ? "▲" : "▼"}
                    </span>
                  )}
                </th>
                <th
                  onClick={() => toggleSortDirection("Description")}
                  className="cursor-pointer px-4 py-2 text-center text-base font-medium text-gray-500 uppercase tracking-wider"
                >
                  Description
                  {sortColumn === "Description" && (
                    <span className="text-sm px-1 items-center text-blue-400">
                      {sortDirection === "asc" ? "▲" : "▼"}
                    </span>
                  )}
                </th>
                <th
                  onClick={() => toggleSortDirection("Url")}
                  className="cursor-pointer items-center px-4 py-2 text-base font-medium text-gray-500 uppercase tracking-wider"
                >
                  Link
                  {sortColumn === "Url" && (
                    <span className="text-sm px-1 items-center text-blue-400">
                      {sortDirection === "asc" ? "▲" : "▼"}
                    </span>
                  )}
                </th>
                <th
                  onClick={() => toggleSortDirection("Price")}
                  className="cursor-pointer px-4 py-2 text-center text-base font-medium text-gray-500 uppercase tracking-wider"
                >
                  Price
                  {sortColumn === "Price" && (
                    <span className="text-sm px-1 items-center text-blue-400">
                      {sortDirection === "asc" ? "▲" : "▼"}
                    </span>
                  )}
                </th>
              </tr>
            </thead>
            <tbody
              className={`${
                isDarkMode ? "text-white" : "text-black"
              } divide-y divide-gray-200`}
            >
              {contents.length > 0 &&
                contents.map((content) => (
                  <tr
                    className="max-w-[300px] lg:min-w-[400px] lg:max-w-[400px] truncate "
                    key={content.id}
                  >
                    <td className="px-4 py-2 whitespace-nowrap font-medium">
                      <input
                        type="checkbox"
                        checked={selectedContents.includes(content.id)}
                        onChange={() => handleSelectContent(content.id)}
                      />
                    </td>
                    <td className="cursor-pointer px-4 py-2 whitespace-nowrap text-base text-center">
                      <h2 className="text-[16px]">{content.title}</h2>
                    </td>
                    <td className="cursor-pointer px-4 py-2 text-base break-words text-center">
                      {content.description && content.description.length > 25
                        ? content.description.slice(0, 40) + "..."
                        : content.description || "No description available"}
                    </td>
                    <td className="px-4 py-2 break-words text-base text-[#2083D8] text-center cursor-pointer">
                      {content.url}
                    </td>
                    <td className="px-4 py-2 break-words text-base text-[#2083D8] text-center cursor-pointer">
                      {content.price} EGP
                    </td>
                  </tr>
                ))}

              {!contents.length && !loading && (
                <tr>
                  <td
                    colSpan="5"
                    className={`${
                      isDarkMode ? "text-white" : "text-gray-500"
                    } px-2 py-5 text-center text-lg text-gray-500`}
                  >
                    No Quizzes Available
                  </td>
                </tr>
              )}
            </tbody>
          </table>

          {loading && (
            <div className="absolute top-0 left-0 w-full h-full flex justify-center items-center bg-white bg-opacity-50">
              <div className="loader">
                <div className="dot"></div>
                <div className="dot"></div>
                <div className="dot"></div>
              </div>
            </div>
          )}
        </div>

        {error && <p className="mt-4 text-lg text-red-600">{error.message}</p>}
        <div className="flex items-center justify-end py-4 text-black">
          <label
            htmlFor="pageSize"
            className={`${
              isDarkMode ? "text-white" : "text-gray-700"
            } mr-2 text-sm`}
          >
            Items per page:
          </label>
          <select
            id="pageSize"
            value={pageSize}
            onChange={(e) => {
              setPageSize(Number(e.target.value));
              setPage(1);
            }}
            className="px-4 py-2 border border-gray-300 rounded-lg"
          >
            {[20, 30, 50].map((size) => (
              <option key={size} value={size}>
                {size}
              </option>
            ))}
          </select>
        </div>
        <div className="mt-4 flex flex-col sm:flex-row justify-between items-center">
          <button
            onClick={() => handlePageChange(page - 1)}
            disabled={page <= 1}
            className={`px-4 py-2 bg-gray-300 text-black rounded-lg ${
              page <= 1 ? "opacity-50 cursor-not-allowed" : "hover:bg-gray-400"
            }`}
          >
            Previous
          </button>
          <span className={"text-lg mt-2 sm:mt-0"}>
            Page {page} of {totalPages}
          </span>
          <button
            onClick={() => handlePageChange(page + 1)}
            disabled={page >= totalPages}
            className={`px-4 py-2 bg-gray-300 text-black rounded-lg ${
              page >= totalPages
                ? "opacity-50 cursor-not-allowed"
                : "hover:bg-gray-400"
            }`}
          >
            Next
          </button>
        </div>
      </div>
    </div>
  );
}
