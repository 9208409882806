import React, { useCallback, useContext, useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { MdEdit } from "react-icons/md";
import { IoIosArrowBack, IoIosArrowForward } from "react-icons/io";
import { IoCloseOutline } from "react-icons/io5";
import { FaUsers } from "react-icons/fa";
import { format } from "date-fns";

import { DarkModeContext } from "../../../Context/DarkModeContext.jsx";
import axiosInstance from "../../../Context/axiosInstance.js";

function Session() {
  const { isDarkMode } = useContext(DarkModeContext);
  const navigate = useNavigate();
  const location = useLocation();
  const { sessionId } = useParams();
  const { state } = location;
  const sessionName = state?.name;

  const params = new URLSearchParams(location.search);
  // Retrieve values from URL parameters
  const pageFromUrl = parseInt(params.get("p"), 10) || 1;
  const pageSizeFromUrl = parseInt(params.get("ps"), 10) || 20;
  const searchQueryFromUrl = params.get("q") || "";
  const sortColumnFromUrl = params.get("sc") || "Name";
  const sortDirectionFromUrl = params.get("sd") || "asc";

  const [totalPages, setTotalPages] = useState(1);
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(20);
  const [searchQuery, setSearchQuery] = useState("");
  const [sortColumn, setSortColumn] = useState("CreatedOnUtc");
  const [sortDirection, setSortDirection] = useState("desc");

  const [loading, setLoading] = useState(false);
  const [loadingStudents, setLoadingStudents] = useState(false);
  const [error, setError] = useState(null);
  const [session, setSession] = useState({});
  const [users, setUsers] = useState([]); // State for users
  const [isEditModalOpen, setEditModalOpen] = useState(false);
  const [editSessionData, setEditSessionData] = useState(null);
  const [savingEdit, setSavingEdit] = useState(false);
  const [inputErrors, setInputErrors] = useState({});

  const fetchSession = useCallback(async () => {
    setLoading(true);
    setError(null);

    try {
      const response = await axiosInstance.get(`/sessions/${sessionId}`);
      setSession(response.data || {});
      setEditSessionData(response.data || {});
    } catch (error) {
      setError(error.message || "Failed to fetch session.");
    } finally {
      setLoading(false);
    }
  }, [sessionId]);

  const fetchReservedStudents = useCallback(async () => {
    setLoadingStudents(true);
    setError(null);

    try {
      const response = await axiosInstance.get(
        `/sessions/${sessionId}/reservations?page=${page}&pageSize=${pageSize}&sortColumn=${sortColumn}&sortDirection=${sortDirection}&search=${encodeURIComponent(
          searchQuery
        )}`
      );
      setUsers(response.data.response || []);
      setTotalPages(Math.ceil(response.data.totalCount / pageSize));
    } catch (error) {
      setError(error.message || "Failed to fetch users.");
    } finally {
      setLoadingStudents(false);
    }
  }, [sessionId, page, pageSize, sortColumn, sortDirection, searchQuery]);

  useEffect(() => {
    fetchSession();
  }, [fetchSession]);

  useEffect(() => {
    fetchReservedStudents();
  }, [fetchReservedStudents]);

  useEffect(() => {
    setPage(1);
  }, [searchQuery]);

  // Handlers for pagination
  const goToNextPage = () => {
    navigate(+1);
  };

  const goToPreviousPage = () => {
    const queryParams = new URLSearchParams({
      p: pageFromUrl,
      ps: pageSizeFromUrl,
      q: searchQueryFromUrl,
      sc: sortColumnFromUrl,
      sd: sortDirectionFromUrl,
    }).toString();

    navigate(`/live-sessions/sessions?${queryParams}`);
  };

  const handleOpenEditModal = async () => {
    fetchSession();
    setEditModalOpen(true);
  };

  const handleClose = () => {
    setInputErrors({});
    setEditSessionData({
      name: "",
      description: "",
      startDate: "",
      endDate: "",
      location: "",
    });
    setEditModalOpen(false);
  };

  // Convert UTC date to local date string for date input
  const formatDateToLocal = (utcDate) => {
    const localDate = new Date(utcDate);
    return localDate.toISOString().split("T")[0]; // Returns YYYY-MM-DD
  };

  // Convert UTC date to local time string for time input
  const formatTimeToLocal = (utcDate) => {
    const localDate = new Date(utcDate);
    return localDate.toTimeString().split(" ")[0].substring(0, 5); // Returns HH:mm
  };

  // Function to create a new ISO date string from date and time
  const createISOString = (dateStr, timeStr) => {
    const [year, month, day] = dateStr.split("-");
    const [hours, minutes] = timeStr.split(":");

    // Use the local time based on input
    const localDate = new Date(year, month - 1, day, hours, minutes);
    return localDate.toISOString(); // Convert back to ISO
  };

  const handleEditSession = async (e) => {
    e.preventDefault();
    setSavingEdit(true);
    setInputErrors([]);

    // const newErrors = {};
    // // Validation checks
    // if (!editSessionData.name) newErrors.name = "Name is required.";
    // if (!editSessionData.description)
    //   newErrors.description = "Description is required.";
    // if (!editSessionData.startDate)
    //   newErrors.startDate = "Start Date is required.";
    // if (!editSessionData.endDate) newErrors.endDate = "End Date is required.";
    // if (!editSessionData.location) newErrors.location = "Location is required.";

    // if (Object.keys(newErrors).length) {
    //   setInputErrors(newErrors);
    //   setSavingEdit(false);
    //   return;
    // }

    const startDate = new Date(editSessionData.startDate);
    const endDate = new Date(editSessionData.endDate);

    // Convert to UTC before sending
    const utcStartDate = new Date(
      startDate.getTime() + startDate.getTimezoneOffset() * 60000
    );
    const utcEndDate = new Date(
      endDate.getTime() + endDate.getTimezoneOffset() * 60000
    );

    // Format dates using date-fns
    const formattedStartDate = format(utcStartDate, "dd/MM/yyyy HH:mm");
    const formattedEndDate = format(utcEndDate, "dd/MM/yyyy HH:mm");

    const { id, ...dataToUpdate } = editSessionData;
    dataToUpdate.startDate = formattedStartDate;
    dataToUpdate.endDate = formattedEndDate;

    try {
      const response = await axiosInstance.put(
        `/sessions/${sessionId}`,
        dataToUpdate
      );

      if (response.status === 200) {
        handleClose();
        await fetchSession();
      }
    } catch (error) {
      const errorData =
        error.response?.data?.errors || error.response?.data?.message;
      let errorMessages = [];

      if (Array.isArray(errorData)) {
        errorMessages = errorData.map((err) => ({
          reason: err.reason || "An unknown error occurred.",
        }));
      } else if (typeof errorData === "object" && errorData !== null) {
        // If it's an object, create error messages from the keys and reasons
        errorMessages = Object.entries(errorData).map(([key, value]) => ({
          name: key,
          reason:
            typeof value === "object"
              ? value.reason || JSON.stringify(value)
              : value,
        }));
      } else if (typeof errorData === "string") {
        errorMessages = [{ reason: errorData }];
      } else {
        errorMessages = [{ reason: "An unexpected error occurred." }];
      }

      setInputErrors(errorMessages);
    } finally {
      setSavingEdit(false);
    }
  };

  const pathSegments = location.pathname.slice(1).split("/");
  const formattedSegments = pathSegments.map((segment) =>
    segment.replace(/[_-]/g, " ").replace(/\b\w/g, (char) => char.toUpperCase())
  );

  if (sessionName) {
    const middleIndex = Math.floor(formattedSegments.length / 2);
    formattedSegments.splice(
      middleIndex,
      1,
      sessionName.charAt(0).toUpperCase() + sessionName.slice(1)
    );
  }

  const formattedPath = formattedSegments.join(" > ");

  //handle changing of table page not main page
  const handlePageChange = (newPage) => {
    if (newPage >= 1 && newPage <= totalPages) {
      setPage(newPage);
      navigate(`?page=${newPage}&pageSize=${pageSize}`);
    }
  };

  // control sorting direction
  const toggleSortDirection = (column) => {
    if (sortColumn === column) {
      setSortDirection((prev) => (prev === "asc" ? "desc" : "asc"));
    } else {
      setSortColumn(column);
      setSortDirection("asc");
    }
  };

  return (
    <div
      className={`flex flex-col justify-center gap-6 ${
        isDarkMode ? "bg-[#292929] text-black" : "bg-white text-black"
      } p-4 lg:p-8 min-h-screen`}
    >
      {/* Left Side: Session Details */}
      <div
        className={`${
          isDarkMode ? "bg-[#1e1e1e] text-white" : "bg-customBlue text-white"
        } p-4 lg:p-8 mt-[40px] lg:mt-0 rounded-[20px]`}
      >
        <div className="lg:flex w-full justify-between items-center text-gray-700 text-sm capitalize pb-4">
          <div
            className={`flex px-2 py-3 ${
              isDarkMode ? "bg-customRed" : "bg-white"
            } gap-1 items-center py-2 rounded-[10px]`}
          >
            <button
              onClick={goToPreviousPage}
              className="w-[24px] h-[20px] flex justify-center items-center bg-gray-300 rounded-[6px]"
            >
              <IoIosArrowBack />
            </button>
            <button
              onClick={goToNextPage}
              className="w-[24px] h-[20px] flex justify-center items-center bg-gray-300 rounded-[6px]"
            >
              <IoIosArrowForward />
            </button>
            <div
              className={`${
                isDarkMode ? "text-white" : "text-black"
              } text-sm capitalize px-2`}
            >
              <span>{formattedPath}</span>
            </div>
          </div>

          <div className="flex flex-col lg:flex-row lg:gap-2 py-2">
            <button
              // onClick={() => setEditModalOpen(true)}
              onClick={handleOpenEditModal}
              className="flex items-center mb-2 lg:mb-0 px-4 py-2 lg:px-6 lg:py-3 justify-center bg-[#2264E5] text-white rounded-lg hover:bg-blue-600"
            >
              <MdEdit size={25} className="pr-2" />
              Edit Session
            </button>
          </div>
        </div>

        {isEditModalOpen && (
          <div className="fixed inset-0 flex items-center justify-center z-50 bg-black bg-opacity-70">
            <div className="relative bg-white text-black rounded-lg shadow-lg w-96 p-8">
              {/* Close icon */}
              <IoCloseOutline
                onClick={handleClose}
                className="absolute top-4 right-4 text-2xl cursor-pointer text-gray-500 hover:text-red-500"
              />
              <h2 className="text-2xl font-bold text-center mb-6">
                Edit Session
              </h2>
              {loading ? (
                <div className="h-[580px]">
                  <div className="flex justify-center items-center h-full">
                    <div className="loader">
                      <div className="dot"></div>
                      <div className="dot"></div>
                      <div className="dot"></div>
                    </div>
                  </div>
                </div>
              ) : (
                <form onSubmit={handleEditSession}>
                  {/* Name Field */}
                  <div className="mb-6">
                    <label className="block text-sm font-medium mb-1">
                      Name
                    </label>
                    <input
                      type="text"
                      className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm p-2"
                      value={editSessionData?.name || ""}
                      onChange={(e) =>
                        setEditSessionData({
                          ...editSessionData,
                          name: e.target.value,
                        })
                      }
                    />
                  </div>
                  {/* Description Field */}
                  <div className="mb-6">
                    <label className="block text-sm font-medium mb-1">
                      Description
                    </label>
                    <input
                      type="text"
                      className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm p-2"
                      value={editSessionData?.description || ""}
                      onChange={(e) =>
                        setEditSessionData({
                          ...editSessionData,
                          description: e.target.value,
                        })
                      }
                    />
                  </div>

                  {/* Start Date Field */}
                  <div className="mb-2">
                    <label className="block text-sm font-medium mb-1">
                      Start Date and Time
                    </label>
                    <input
                      type="date"
                      className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm p-2"
                      value={
                        editSessionData?.startDate
                          ? formatDateToLocal(editSessionData.startDate)
                          : ""
                      }
                      onChange={(e) => {
                        const newDate = e.target.value;
                        const currentTime = editSessionData.startDate
                          ? formatTimeToLocal(editSessionData.startDate)
                          : "00:00";
                        setEditSessionData({
                          ...editSessionData,
                          startDate: createISOString(newDate, currentTime),
                        });
                      }}
                    />
                  </div>

                  {/* Start Time Field */}
                  <div className="mb-6">
                    <input
                      type="time"
                      className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm p-2"
                      value={
                        editSessionData?.startDate
                          ? formatTimeToLocal(editSessionData.startDate)
                          : ""
                      }
                      onChange={(e) => {
                        const newTime = e.target.value;
                        const currentDate = editSessionData.startDate
                          ? formatDateToLocal(editSessionData.startDate)
                          : "";
                        setEditSessionData({
                          ...editSessionData,
                          startDate: createISOString(currentDate, newTime),
                        });
                      }}
                    />
                  </div>

                  {/* End Date Field */}
                  <div className="mb-2">
                    <label className="block text-sm font-medium mb-1">
                      End Date and Time
                    </label>
                    <input
                      type="date"
                      className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm p-2"
                      value={
                        editSessionData?.endDate
                          ? formatDateToLocal(editSessionData.endDate)
                          : ""
                      }
                      onChange={(e) => {
                        const newDate = e.target.value;
                        const currentTime = editSessionData.endDate
                          ? formatTimeToLocal(editSessionData.endDate)
                          : "00:00";
                        setEditSessionData({
                          ...editSessionData,
                          endDate: createISOString(newDate, currentTime),
                        });
                      }}
                    />
                  </div>

                  {/* End Time Field */}
                  <div className="mb-6">
                    <input
                      type="time"
                      className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm p-2"
                      value={
                        editSessionData?.endDate
                          ? formatTimeToLocal(editSessionData.endDate)
                          : ""
                      }
                      onChange={(e) => {
                        const newTime = e.target.value;
                        const currentDate = editSessionData.endDate
                          ? formatDateToLocal(editSessionData.endDate)
                          : "";
                        setEditSessionData({
                          ...editSessionData,
                          endDate: createISOString(currentDate, newTime),
                        });
                      }}
                    />
                  </div>

                  {/* Location Field */}
                  <div className="mb-6">
                    <label className="block text-sm font-medium mb-1">
                      Location
                    </label>
                    <input
                      type="text"
                      className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm p-2"
                      value={editSessionData?.location || ""}
                      onChange={(e) =>
                        setEditSessionData({
                          ...editSessionData,
                          location: e.target.value,
                        })
                      }
                    />
                  </div>
                  {inputErrors.length > 0 && (
                    <div className="text-red-500 pb-2">
                      {inputErrors.map((error, index) => (
                        <p key={index}>
                          {typeof error === "string"
                            ? error
                            : error && typeof error === "object"
                            ? `${error.reason}`
                            : "An unknown error occurred."}
                        </p>
                      ))}
                    </div>
                  )}
                  {/* Buttons */}
                  <div className="flex justify-center mt-8">
                    <button
                      type="submit"
                      className={`${
                        isDarkMode
                          ? "bg-customRed hover:bg-red-600"
                          : "bg-blue-400 hover:bg-blue-600"
                      } text-white rounded-md px-4 py-2 transition duration-200 transform  hover:scale-105`}
                    >
                      {savingEdit ? "Saving..." : "Save Changes"}
                    </button>
                  </div>
                </form>
              )}
            </div>
          </div>
        )}

        {/* Session Details */}
        <div className="overflow-x-auto pb-4">
          <table
            className={`min-w-full divide-y ${
              isDarkMode ? "divide-gray-200" : "divide-gray-500"
            }`}
          >
            <thead>
              <tr>
                <th className="cursor-pointer px-4 py-2 text-center text-base font-medium uppercase tracking-wider">
                  Name
                </th>
                <th className="cursor-pointer px-4 py-2 text-center text-base font-medium uppercase tracking-wider">
                  Description
                </th>
                <th className="px-4 py-2 text-center text-base font-medium uppercase tracking-wider">
                  Start Date
                </th>
                <th className="px-4 py-2 text-center text-base font-medium uppercase tracking-wider">
                  End Date
                </th>
                <th className="px-4 py-2 text-center text-base font-medium uppercase tracking-wider">
                  Location
                </th>
              </tr>
            </thead>
            <tbody className={`divide-y divide-gray-200`}>
              {session.id && (
                <tr key={session.id}>
                  <td className="px-4 py-2 text-base text-center">
                    {session.name}
                  </td>
                  <td className="px-4 py-2 text-base text-center">
                    {session.description}
                  </td>
                  <td className="px-4 py-2 text-base text-center">
                    {new Date(session.startDate).toLocaleString("en-EG", {
                      timeZone: "Africa/Cairo",
                    })}
                  </td>
                  <td className="px-4 py-2 text-base text-center">
                    {new Date(session.endDate).toLocaleString("en-EG", {
                      timeZone: "Africa/Cairo",
                    })}
                  </td>
                  <td className="px-4 py-2 text-base text-center">
                    {session.location}
                  </td>
                </tr>
              )}
              {!session.id && !loading && (
                <tr className="h-[650px]">
                  <td
                    colSpan="5"
                    className={`${
                      isDarkMode ? "text-white" : "text-gray-500"
                    } text-center text-lg`}
                  >
                    No session found
                  </td>
                </tr>
              )}
              {loading && !isEditModalOpen && (
                <tr>
                  <td
                    colSpan="5"
                    className={`${
                      isDarkMode ? "text-white" : "text-gray-500"
                    } px-2 py-5 text-center text-lg`}
                  >
                    <div className="flex justify-center items-center">
                      <div className="loader">
                        <div className="dot"></div>
                        <div className="dot"></div>
                        <div className="dot"></div>
                      </div>
                    </div>
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>

        {error && (
          <tr>
            <td
              colSpan="6"
              className={`${
                isDarkMode ? "text-white" : "text-gray-500"
              } px-2 py-5 text-center text-lg`}
            >
              No session Available
            </td>
          </tr>
        )}
        {error && <p className="mt-4 text-lg text-red-600">{error.message}</p>}
      </div>

      {/* Right Side: Users List */}
      <div
        className={`${
          isDarkMode
            ? "bg-[#1e1e1e] text-white"
            : "bg-white text-black border-2 border-gray-200"
        } p-4 lg:p-8 mt-[40px] lg:mt-0 rounded-[20px] shadow-xl flex-1`}
      >
        {/* <div className="flex flex-col lg:flex-row lg:items-center lg:justify-between mb-4"> */}
        <div className="flex justify-center lg:mr-[150px] items-center gap-3 py-4 lg:py-0 lg:flex-grow">
          <h2 className="text-lg font-semibold">Student Reservations</h2>
          <FaUsers size={30} />
        </div>
        <div className="flex py-6 justify-end">
          <div className="flex flex-col flex-shrink-0">
            <input
              type="text"
              placeholder="Search"
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
              className={`${
                isDarkMode ? "bg-[#292929]" : "bg-gray-100"
              } px-4 py-2 border border-gray-300 rounded-lg`}
            />
          </div>
        </div>
        {/* </div> */}

        <div className="h-[500px] flex flex-col">
          {loadingStudents ? (
            <div className="flex justify-center items-center h-full">
              <div className="loader">
                <div className="dot"></div>
                <div className="dot"></div>
                <div className="dot"></div>
              </div>
            </div>
          ) : (
            <div className="overflow-x-auto h-full">
              <table className="min-w-full divide-y divide-gray-200">
                <thead>
                  <tr>
                    <th
                      onClick={() => toggleSortDirection("UserName")}
                      className="cursor-pointer px-4 py-2 text-center text-base font-medium text-gray-500 uppercase tracking-wider"
                    >
                      User Name
                      {sortColumn === "UserName" && (
                        <span className="text-sm px-1 items-center text-blue-400">
                          {sortDirection === "asc" ? "▲" : "▼"}
                        </span>
                      )}
                    </th>
                    <th
                      onClick={() => toggleSortDirection("WhatsAppNumber")}
                      className="cursor-pointer px-4 py-2 text-center text-base font-medium text-gray-500 uppercase tracking-wider"
                    >
                      WhatsApp Number
                      {sortColumn === "WhatsAppNumber" && (
                        <span className="text-sm px-1 items-center text-blue-400">
                          {sortDirection === "asc" ? "▲" : "▼"}
                        </span>
                      )}
                    </th>
                    <th
                      onClick={() => toggleSortDirection("CreatedOnUtc")}
                      className="cursor-pointer px-4 py-2 text-center text-base font-medium text-gray-500 uppercase tracking-wider"
                    >
                      Created On
                      {sortColumn === "CreatedOnUtc" && (
                        <span className="text-sm px-1 items-center text-blue-400">
                          {sortDirection === "asc" ? "▲" : "▼"}
                        </span>
                      )}
                    </th>
                  </tr>
                </thead>
                <tbody className="divide-y divide-gray-200">
                  {users.length > 0 &&
                    users.map((user) => (
                      <tr key={user.id}>
                        <td className="px-4 py-2 text-center">
                          {user.userName}
                        </td>
                        <td className="px-4 py-2 text-center">
                          {user.userWhatsAppNumber}
                        </td>
                        <td className="px-4 py-2 text-center">
                          {new Date(user.createdOnUtc).toLocaleString("en-EG", {
                            timeZone: "Africa/Cairo",
                          })}
                        </td>
                      </tr>
                    ))}

                  {users.length === 0 && !loadingStudents && (
                    <tr>
                      <td
                        colSpan="3"
                        className={`${
                          isDarkMode ? "text-white" : "text-gray-500"
                        } text-center text-lg h-[450px]`}
                      >
                        No Students found in this session
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
          )}
        </div>
        <div className="flex items-center justify-end py-4 text-black">
          <label
            htmlFor="pageSize"
            className={`${
              isDarkMode ? "text-white" : "text-gray-700"
            } mr-2 text-sm`}
          >
            Students per page:
          </label>
          <select
            id="pageSize"
            value={pageSize}
            onChange={(e) => {
              setPageSize(Number(e.target.value));
              setPage(1);
            }}
            className="px-4 py-2 border border-gray-300 rounded-lg"
          >
            {[20, 30, 50].map((size) => (
              <option key={size} value={size}>
                {size}
              </option>
            ))}
          </select>
        </div>
        <div className="mt-4 flex flex-col sm:flex-row justify-between items-center">
          <button
            onClick={() => handlePageChange(page - 1)}
            disabled={page <= 1}
            className={`px-4 py-2 bg-gray-300 text-black rounded-lg ${
              page <= 1 ? "opacity-50 cursor-not-allowed" : "hover:bg-gray-400"
            }`}
          >
            Previous
          </button>
          <span className={"text-lg mt-2 sm:mt-0"}>
            Page {page} of {totalPages}
          </span>
          <button
            onClick={() => handlePageChange(page + 1)}
            disabled={page >= totalPages}
            className={`px-4 py-2 bg-gray-300 text-black rounded-lg ${
              page >= totalPages
                ? "opacity-50 cursor-not-allowed"
                : "hover:bg-gray-400"
            }`}
          >
            Next
          </button>
        </div>
      </div>
    </div>
  );
}

export default Session;
