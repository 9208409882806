import React, { useState } from "react";
import { IoMdAdd } from "react-icons/io";
import { MdArrowDropDown } from "react-icons/md"; // For dropdown arrow
import ContentModal from "./ContentModal";

function DropdownButton({ handleAddModal, refreshCourses }) {
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [isAddModalOpen, setIsAddModalOpen] = useState(false);
  const [contentType, setContentType] = useState(""); // Track selected content type

  // Open and close the modal
  const handleAddContentModalOpen = (type) => {
    setContentType(type); // Set the selected content type
    setIsAddModalOpen(true);
    setIsDropdownOpen(false); // Close dropdown when modal opens
  };

  const handleAddCourseModalClose = () => {
    setIsAddModalOpen(false);
    setContentType(""); // Reset content type when modal closes
    refreshCourses();
  };

  // Toggle dropdown visibility (only triggered by the arrow click)
  const toggleDropdown = (e) => {
    e.stopPropagation(); // Prevent event from bubbling to the button
    setIsDropdownOpen((prev) => !prev);
  };

  return (
    <div className="relative flex justify-center">
      {/* Dropdown button */}
      <button className="flex items-center mb-2 lg:mb-0 px-4 justify-center bg-[#2264E5] text-white rounded-lg transition-opacity">
        <div className="flex gap-3 items-center w-full">
          <div
            onClick={handleAddModal}
            className="flex-1 py-3 gap-2 flex items-center"
          >
            <IoMdAdd size={21} />
            Add Course
          </div>
          <div className="w-1/5 flex justify-center items-center">
            <div
              onClick={toggleDropdown}
              className="border-l px-2 py-1 border-white h-full flex justify-center items-center"
            >
              <MdArrowDropDown
                size={32}
                className={` text-white cursor-pointer transition-transform ${
                  isDropdownOpen
                    ? "rotate-180 ease-in-out duration-500"
                    : "rotate-0 ease-in-out duration-500"
                }`}
              />
            </div>
          </div>
        </div>
      </button>

      {/* Dropdown Menu */}
      <div
        className={`absolute top-full mt-2 left-0 bg-white shadow-lg rounded-lg w-full border border-gray-300 transition-all duration-300 ease-in-out ${
          isDropdownOpen
            ? "transform -translate-y-0 opacity-100"
            : "transform -translate-y-[-100%] opacity-0 pointer-events-none"
        }`}
      >
        <div className="p-4 text-sm text-gray-500">Select Content Type:</div>
        <button
          onClick={() => handleAddContentModalOpen("video")}
          className="w-full text-center px-4 py-2 hover:bg-gray-100 rounded-lg text-blue-500"
        >
          Video
        </button>
        <button
          onClick={() => handleAddContentModalOpen("material")}
          className="w-full text-center px-4 py-2 hover:bg-gray-100 rounded-lg text-blue-500"
        >
          Material
        </button>
        <button
          onClick={() => handleAddContentModalOpen("quiz")}
          className="w-full text-center px-4 py-2 hover:bg-gray-100 rounded-lg text-blue-500"
        >
          Quiz
        </button>
        <button
          onClick={() => handleAddContentModalOpen("assignment")}
          className="w-full text-center px-4 py-2 hover:bg-gray-100 rounded-lg text-blue-500"
        >
          Assignment
        </button>
      </div>

      {/* Render ContentModal based on selected content type */}
      {isAddModalOpen && (
        <ContentModal
          isModalOpen={isAddModalOpen}
          closeModal={handleAddCourseModalClose}
          type={contentType}
        />
      )}
    </div>
  );
}

export default DropdownButton;
