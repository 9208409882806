import React, { useContext, useEffect, useState } from "react";
import { MdDelete, MdOutlineDelete } from "react-icons/md";
import { IoMdAdd, IoIosCloseCircleOutline } from "react-icons/io";

import "../../index.css";
import { DarkModeContext } from "../../Context/DarkModeContext.jsx";
import axiosInstance from "../../Context/axiosInstance.js";

function WalletPage() {
  const { isDarkMode } = useContext(DarkModeContext);

  const [codes, setCodes] = useState([]);
  const [selectedCodes, setSelectedCodes] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(20);
  const [totalPages, setTotalPages] = useState(1);

  // States for sorting
  const [searchQuery, setSearchQuery] = useState("");
  const [sortColumn, setSortColumn] = useState("CreatedOnUtc");
  const [sortDirection, setSortDirection] = useState("desc");

  // States for course options modal
  const [isModalOpen, setModalOpen] = useState(false);
  const [animationClass, setAnimationClass] = useState("");

  //State for Confirmation Modal
  const [isConfirmDeleteModalOpen, setConfirmDeleteModalOpen] = useState(false);
  const [deletingCode, setDeletingCode] = useState(false);

  // Form Data
  const [loadingAddCode, setLoadingAddCode] = useState(false);
  const [formData, setFormData] = useState({
    count: "",
    amount: "",
  });

  const [formErrors, setFormErrors] = useState({
    count: "",
    amount: "",
  });
  const [errors, setErrors] = useState([]);

  useEffect(() => {
    // Reset to page 1 whenever the search query changes
    setPage(1);
  }, [searchQuery]);

  // GET Request: Fetching Data
  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      setError(null);

      try {
        const response = await axiosInstance.get(
          `/codes?page=${page}&pageSize=${pageSize}&sortColumn=${sortColumn}&sortDirection=${sortDirection}&search=${encodeURIComponent(
            searchQuery
          )}`
        );

        setCodes(response.data.response);
        setTotalPages(Math.ceil(response.data.totalCount / pageSize));
      } catch (error) {
        if (error.response && error.response.status === 401) {
          // Unauthorized error (token expired or invalid)
          // The Axios interceptor will handle the token refresh and retry.
        } else {
          setError(error.message || "An unexpected error occurred.");
        }
      } finally {
        setLoading(false);
      }
    };
    fetchData();
  }, [page, pageSize, searchQuery, sortColumn, sortDirection]);

  const handleAddCourseModalOpen = () => {
    setModalOpen(true);
    setAnimationClass("slide-in");
  };

  // POST Request: Adding a New Code
  const handleAddCode = async (e) => {
    e.preventDefault();
    setLoadingAddCode(true);

    try {
      const response = await axiosInstance.post("/codes", formData);
      setCodes([...codes, response.data]);
      setModalOpen(false);
    } catch (error) {
      setErrors([error.message || "An unexpected error occurred."]);
    } finally {
      setLoadingAddCode(false);
      window.location.reload();
    }
  };

  // DELETE Request: Removing a Code
  const handleDeleteCode = () => {
    setConfirmDeleteModalOpen(true);
  };

  const handleConfirmDelete = async () => {
    setDeletingCode(true);
    if (selectedCodes.length > 0) {
      try {
        await Promise.all(
          selectedCodes.map((id) => axiosInstance.delete(`/codes/${id}`))
        );
        setCodes(codes.filter((code) => !selectedCodes.includes(code.id)));
        setSelectedCodes([]);
      } catch (error) {
        setErrors([error.message || "An unexpected error occurred."]);
        console.log(errors);
      } finally {
        setConfirmDeleteModalOpen(false);
        setDeletingCode(false);
      }
    }
  };

  const handleFormChange = (e) => {
    const { id, value, type, files } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [id]: type === "file" ? files[0] : value,
    }));
  };

  // Handle selection of courses
  const handleSelectCodes = (codeId) => {
    setSelectedCodes((prevSelected) =>
      prevSelected.includes(codeId)
        ? prevSelected.filter((id) => id !== codeId)
        : [...prevSelected, codeId]
    );
  };

  // Control sorting direction
  const toggleSortDirection = (column) => {
    if (sortColumn === column) {
      setSortDirection((prev) => (prev === "asc" ? "desc" : "asc"));
    } else {
      setSortColumn(column);
      setSortDirection("asc");
    }
  };

  const handlePageChange = (newPage) => {
    if (newPage >= 1 && newPage <= totalPages) {
      setPage(newPage);
    }
  };

  const handleAddCourseModalClose = () => {
    setAnimationClass("slide-out");
    setModalOpen(false);
    setFormErrors("");
    setFormData({
      count: "",
      amount: "",
    });
  };

  return (
    <div
      className={`flex flex-col lg:flex-row lg:items-start ${
        isDarkMode ? "bg-[#292929] text-black" : "bg-white text-black"
      } p-4 lg:p-8 min-h-screen`}
    >
      <div
        className={`${
          isDarkMode ? "bg-[#1e1e1e] text-white" : "bg-white text-black"
        } p-4 lg:p-8 mt-[40px] lg:mt-0 rounded-lg shadow-xl flex-1`}
      >
        {/*/////////////// CONFIRM DELETE MODAL//////////////////////// */}
        {isConfirmDeleteModalOpen && (
          <div className="fixed inset-0 flex items-center justify-center bg-gray-900 bg-opacity-50 z-50">
            <div
              className={`relative flex flex-col ${
                isDarkMode ? "bg-white text-black" : "bg-white text-black"
              } p-6 rounded-lg shadow-lg w-80`}
            >
              <div className="flex justify-center pb-4">
                <MdDelete className="text-customRed justify-center" size={50} />
              </div>
              <h2 className="text-lg font-bold text-center">
                Confirm Deletion
              </h2>
              <p className="text-center mt-2">
                Are you sure you want to delete
                {selectedCodes.length > 1 ? " these codes?" : " this code?"}
              </p>
              <div className="flex justify-around mt-4">
                <button
                  onClick={() => handleConfirmDelete()}
                  className="bg-red-500 text-white px-4 py-2 rounded"
                >
                  {deletingCode ? "Deleting..." : "Yes"}
                </button>
                <button
                  onClick={() => setConfirmDeleteModalOpen(false)}
                  className="bg-gray-500 text-white px-4 py-2 rounded"
                >
                  Cancel
                </button>
              </div>
            </div>
          </div>
        )}

        {/*/////////////// ADD COURSE MODAL//////////////////////// */}
        {isModalOpen && (
          <div className="fixed inset-0 flex items-center justify-center bg-gray-900 bg-opacity-50 z-50">
            <div
              className={`${animationClass} relative flex flex-col ${
                isDarkMode ? "bg-white text-black" : "bg-white text-black"
              } p-6 rounded-[35px] shadow-lg w-80`}
            >
              {/* Icon container */}
              <div className="absolute top-[-30px] left-1/2 transform -translate-x-1/2 flex justify-center items-center">
                <div className="bg-white w-[80px] h-[60px] shadow-md rounded-full border border-gray-300 flex justify-center items-center">
                  <IoMdAdd size={40} className="text-[#2264E5] animate-pulse" />
                </div>
              </div>
              {/* Close icon */}
              <IoIosCloseCircleOutline
                onClick={handleAddCourseModalClose}
                className="absolute top-4 right-4 text-2xl cursor-pointer text-gray-500 hover:text-red-500"
              />

              {/* Add Form */}
              <h2 className="text-2xl font-bold text-center mt-4 mb-4">
                Create A Code
              </h2>
              <form className="flex flex-col gap-4">
                <div className="relative">
                  <input
                    type="text"
                    id="count"
                    placeholder=" "
                    value={formData.count}
                    onChange={handleFormChange}
                    className="floating-input px-3 py-2 mt-2.5 text-[14px] max-w-full bg-white rounded-xl border border-solid border-black border-opacity-20 w-[314px]"
                    required
                  />
                  <label htmlFor="count" className="floating-label">
                    Code Count
                  </label>
                  {formErrors.count && (
                    <p className="text-red-500 text-sm mt-1">
                      {formErrors.count}
                    </p>
                  )}
                </div>

                <div className="relative">
                  <input
                    type="text"
                    id="amount"
                    placeholder=""
                    value={formData.amount}
                    onChange={handleFormChange}
                    className="floating-input px-3 py-2 mt-2.5 text-[14px] max-w-full bg-white rounded-xl border border-solid border-black border-opacity-20 w-[314px]"
                    required
                  />
                  <label htmlFor="amount" className="floating-label">
                    Code Amount
                  </label>
                  {formErrors.amount && (
                    <p className="text-red-500 text-sm mt-1">
                      {formErrors.amount}
                    </p>
                  )}
                </div>
              </form>
              <div className="flex justify-center items-center mt-4">
                <button
                  onClick={handleAddCode}
                  className="flex items-center rounded-md bg-[#2264E5] hover:bg-sky-500 py-2 px-4 border border-transparent text-center text-sm text-white transition-all shadow-sm hover:shadow-lg focus:bg-slate-700 focus:shadow-none active:shadow-none disabled:pointer-events-none disabled:opacity-50 disabled:shadow-none"
                  type="button"
                  disabled={loadingAddCode}
                >
                  {loadingAddCode ? (
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 24 24"
                      fill="none"
                      stroke="currentColor"
                      className="w-4 h-4 mr-1.5 animate-spin"
                    >
                      <circle
                        cx="12"
                        cy="12"
                        r="10"
                        stroke="currentColor"
                        strokeWidth="4"
                        fill="none"
                      />
                    </svg>
                  ) : (
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 24 24"
                      fill="currentColor"
                      className="w-4 h-4 mr-1.5"
                    >
                      <path
                        fillRule="evenodd"
                        d="M10.5 3.75a6 6 0 0 0-5.98 6.496A5.25 5.25 0 0 0 6.75 20.25H18a4.5 4.5 0 0 0 2.206-8.423 3.75 3.75 0 0 0-4.133-4.303A6.001 6.001 0 0 0 10.5 3.75Zm2.03 5.47a.75.75 0 0 0-1.06 0l-3 3a.75.75 0 1 0 1.06 1.06l1.72-1.72v4.94a.75.75 0 0 0 1.5 0v-4.94l1.72 1.72a.75.75 0 1 0 1.06-1.06l-3-3Z"
                        clipRule="evenodd"
                      />
                    </svg>
                  )}
                  {loadingAddCode ? (
                    <span className="dots">Adding</span>
                  ) : (
                    "Add Code"
                  )}
                </button>
              </div>
            </div>
          </div>
        )}
        <div className="flex flex-col lg:flex-row lg:justify-between mb-4">
          <div className="flex flex-col lg:flex-row lg:justify-between pb-2">
            <input
              type="text"
              placeholder="Search"
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
              className={`${
                isDarkMode ? "bg-[#292929]" : "bg-gray-100"
              } px-4 py-2 border border-gray-300 rounded-lg`}
            />
          </div>
          <div className="flex flex-col lg:flex-row lg:gap-2">
            <button
              onClick={(id) => handleAddCourseModalOpen(id)}
              className={`flex items-center mb-2 lg:mb-0 px-4 py-2 lg:px-6 lg:py-3 justify-center bg-[#2264E5] text-white rounded-lg transition-opacity`}
            >
              <IoMdAdd size={30} className="pr-2" />
              Add Code
            </button>
            <button
              onClick={() => {
                selectedCodes.forEach(handleDeleteCode);
              }}
              disabled={selectedCodes.length === 0}
              className={`flex items-center mb-2 lg:mb-0 px-4 py-2 lg:px-6 lg:py-3 justify-center bg-red-500 text-white rounded-lg transition-opacity ${
                selectedCodes.length === 0
                  ? "opacity-40 cursor-not-allowed"
                  : "hover:bg-red-600"
              }`}
            >
              <MdOutlineDelete size={30} className="pr-2" />
              Delete Code
            </button>
          </div>
        </div>

        <div className="max-w-[320px] md:max-w-[700px] lg:max-w-full overflow-x-auto pb-4">
          <table className="min-w-full divide-y divide-gray-200">
            <thead
              className={`${
                isDarkMode ? "bg-[#1e1e1e] text-white" : "bg-white text-black"
              }`}
            >
              <tr>
                <th className="px-4 py-2 text-left font-medium text-gray-500 uppercase tracking-wider">
                  <input
                    type="checkbox"
                    onChange={(e) =>
                      setSelectedCodes(
                        e.target.checked ? codes.map((course) => course.id) : []
                      )
                    }
                    checked={selectedCodes.length === codes.length}
                  />
                </th>
                <th
                  onClick={() => toggleSortDirection("Value")}
                  className="cursor-pointer px-4 py-2 text-center text-base font-medium text-gray-500 uppercase tracking-wider"
                >
                  Code
                  {sortColumn === "Value" && (
                    <span className="text-sm px-1 items-center text-blue-400">
                      {sortDirection === "asc" ? "▲" : "▼"}
                    </span>
                  )}
                </th>
                <th
                  onClick={() => toggleSortDirection("Amount")}
                  className="cursor-pointer px-4 py-2 text-center text-base font-medium text-gray-500 uppercase tracking-wider"
                >
                  Amount
                  {sortColumn === "Amount" && (
                    <span className="text-sm px-1 items-center text-blue-400">
                      {sortDirection === "asc" ? "▲" : "▼"}
                    </span>
                  )}
                </th>
                <th
                  onClick={() => toggleSortDirection("IsRedeemed")}
                  className="cursor-pointer px-4 py-2 text-center text-base font-medium text-gray-500 uppercase tracking-wider"
                >
                  Redeemed
                  {sortColumn === "IsRedeemed" && (
                    <span className="text-sm px-1 items-center text-blue-400">
                      {sortDirection === "asc" ? "▲" : "▼"}
                    </span>
                  )}
                </th>
                <th
                  onClick={() => toggleSortDirection("CreatedOnUtc")}
                  className="cursor-pointer justify-center flex items-center px-4 py-2 text-center text-base font-medium text-gray-500 uppercase tracking-wider"
                >
                  Created On
                  {sortColumn === "CreatedOnUtc" && (
                    <span className="text-sm px-1 items-center text-blue-400">
                      {sortDirection === "asc" ? "▲" : "▼"}
                    </span>
                  )}
                </th>
              </tr>
            </thead>
            <tbody
              className={`${
                isDarkMode ? "text-white" : "text-black"
              } divide-y divide-gray-200`}
            >
              {loading ? (
                <tr>
                  <td
                    colSpan="5"
                    className={`${
                      isDarkMode ? "text-white" : "text-gray-500"
                    } px-2 py-5 text-center text-lg`}
                  >
                    <div className="flex justify-center items-center h-[650px]">
                      <div className="loader">
                        <div className="dot"></div>
                        <div className="dot"></div>
                        <div className="dot"></div>
                      </div>
                    </div>
                  </td>
                </tr>
              ) : codes.length > 0 ? (
                codes.map((code) => (
                  <tr key={code.id}>
                    <td className="px-4 py-2 whitespace-nowrap font-medium">
                      <input
                        type="checkbox"
                        checked={selectedCodes.includes(code.id)}
                        onChange={() => handleSelectCodes(code.id)}
                      />
                    </td>
                    <td className="px-4 py-2 whitespace-nowrap text-base text-center">
                      {code.value}
                    </td>
                    <td className="px-4 py-2 whitespace-nowrap text-base text-center text-[#2083D8]">
                      {code.amount} EGP
                    </td>
                    <td className="px-4 py-2 whitespace-nowrap text-base text-center text-[#2083D8]">
                      {code.isRedeemed ? "Yes" : "No"}
                    </td>
                    <td className="px-4 py-2 whitespace-nowrap text-base text-center text-[#2083D8]">
                      {new Date(code.createdOnUtc).toLocaleString("en-EG", {
                        timeZone: "Africa/Cairo",
                      })}
                    </td>
                  </tr>
                ))
              ) : (
                error && (
                  <tr>
                    <td
                      colSpan="5"
                      className={`${
                        isDarkMode ? "text-white" : "text-gray-500"
                      } px-2 py-5 text-center text-lg`}
                    >
                      No Codes Available
                    </td>
                  </tr>
                )
              )}
            </tbody>
          </table>
        </div>
        <div className="flex items-center justify-end py-4 text-black">
          <label
            htmlFor="pageSize"
            className={`${
              isDarkMode ? "text-white" : "text-gray-700"
            } mr-2 text-sm`}
          >
            Codes per page:
          </label>
          <select
            id="pageSize"
            value={pageSize}
            onChange={(e) => {
              setPageSize(Number(e.target.value));
              setPage(1);
            }}
            className="px-4 py-2 border border-gray-300 rounded-lg"
          >
            {[20, 30, 50].map((size) => (
              <option key={size} value={size}>
                {size}
              </option>
            ))}
          </select>
        </div>
        <div className="mt-4 flex flex-col sm:flex-row justify-between items-center">
          <button
            onClick={() => handlePageChange(page - 1)}
            disabled={page <= 1}
            className={`px-4 py-2 bg-gray-300 text-black rounded-lg ${
              page <= 1 ? "opacity-50 cursor-not-allowed" : "hover:bg-gray-400"
            }`}
          >
            Previous
          </button>
          <span className={"text-lg mt-2 sm:mt-0"}>
            Page {page} of {totalPages}
          </span>
          <button
            onClick={() => handlePageChange(page + 1)}
            disabled={page >= totalPages}
            className={`px-4 py-2 bg-gray-300 text-black rounded-lg ${
              page >= totalPages
                ? "opacity-50 cursor-not-allowed"
                : "hover:bg-gray-400"
            }`}
          >
            Next
          </button>
        </div>
      </div>
    </div>
  );
}

export default WalletPage;
