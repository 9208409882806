import React, { useState, useEffect, useMemo, useContext } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { IoIosArrowBack, IoIosArrowForward } from "react-icons/io";
import { DarkModeContext } from "../../../../Context/DarkModeContext";
import axiosInstance from "../../../../Context/axiosInstance";

function CourseContentPage() {
  const { isDarkMode } = useContext(DarkModeContext);
  const location = useLocation();
  const navigate = useNavigate();
  const { state } = location;
  const courseId = state?.course.id;

  // STATES FOR FETCHING CONTENT
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [totalPages, setTotalPages] = useState(1);
  const [searchQuery, setSearchQuery] = useState("");
  const [sortColumn, setSortColumn] = useState("Id");
  const [sortDirection, setSortDirection] = useState("desc");

  // STORE FETCHED CONTENT
  const [contents, setContents] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [cheatError, setCheatError] = useState(null);

  const handleOpen = (url) => {
    window.open(url, "_blank"); // Opens the URL in a new tab
  };

  // Get current page, section, and itemsPerPage from URL
  // Memoize the queryParams object
  const queryParams = useMemo(() => {
    const params = new URLSearchParams(location.search);
    return params;
  }, [location.search]);
  const section = queryParams.get("section") || "";
  const initialPage = parseInt(queryParams.get("page")) || 1;

  // State for pagination
  const [currentPage, setCurrentPage] = useState(initialPage);

  // Effect to scroll to top on page change
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [currentPage]);

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      setError("");

      try {
        const response = await axiosInstance.get(
          `/courses/${courseId}/assignments?page=${page}&pageSize=${pageSize}&sortColumn=${sortColumn}&sortDirection=${sortDirection}&search=${encodeURIComponent(
            searchQuery
          )}`
        );

        setContents(response.data.response);
        setLoading(false);
        setTotalPages(Math.ceil(response.data.totalCount / pageSize));
      } catch (error) {
        if (error.response) {
          if (error.response.status === 401) {
            // Unauthorized error (token expired or invalid)
          } else if (error.response.status === 403) {
            setCheatError("Please Buy the Course to Access its content");
          } else {
            setError(
              error.response.data.message || "An unexpected error occurred."
            );
          }
        } else if (error.request) {
          setError("No response received from server.");
        } else {
          setError(error.message || "An unexpected error occurred.");
        }
      }
    };
    fetchData();
  }, [courseId, page, pageSize, searchQuery, sortColumn, sortDirection]);

  // Effect to update currentPage based on URL query parameters
  useEffect(() => {
    const page = parseInt(queryParams.get("page")) || 1;
    setCurrentPage(page);
  }, [queryParams]);

  // Handlers for pagination
  const goToNextPage = () => {
    navigate(+1);
  };

  const goToPreviousPage = () => {
    navigate(-1);
  };

  // control sorting direction
  const toggleSortDirection = (column) => {
    if (sortColumn === column) {
      setSortDirection((prev) => (prev === "asc" ? "desc" : "asc"));
    } else {
      setSortColumn(column);
      setSortDirection("asc");
    }
  };

  // Format path to include the section name
  const formattedPath = `${decodeURIComponent(location.pathname) // Decode URL encoding
    .slice(1) // Remove leading slash
    .replace(/[_-]/g, " ") // Replace underscores or hyphens with spaces
    .replace(/[,.\\~]/g, ",") // Replace commas, periods, backslashes, and tilde with spaces
    .replace(/\b\w/g, (char) => char.toUpperCase()) // Capitalize first letter of each word
    .replace(/\//g, " > ")}
  > ${section.charAt(0).toUpperCase() + section.slice(1)}`; // Append the current section

  return (
    // <div className="flex-1 p-8 bg-customBlue text-black min-h-screen">
    <div
      className={`flex-1 p-8 min-h-screen ${
        isDarkMode ? "bg-[#121212] text-white" : "bg-customBlue text-black"
      }`}
    >
      {/* <div className="bg-white text-black p-8 mt-1 rounded-lg shadow-lg"> */}
      <div
        className={`${
          isDarkMode ? "bg-[#1e1e1e] text-white" : "bg-white text-black"
        }  p-8 mt-1 rounded-lg shadow-lg`}
      >
        {/* PATH AND CONTROLS */}
        <div className="lg:flex w-full justify-between items-center mb-4 text-gray-700 text-sm capitalize">
          <div className="flex gap-1">
            <button
              onClick={goToPreviousPage}
              className="w-[24px] h-[20px] flex justify-center items-center bg-gray-300 rounded-[6px] disabled:opacity-50"
            >
              <IoIosArrowBack />
            </button>
            <button
              onClick={goToNextPage}
              className="w-[24px] h-[20px] flex justify-center items-center bg-gray-300 rounded-[6px] disabled:opacity-50"
            >
              <IoIosArrowForward />
            </button>

            <div
              className={`${
                isDarkMode ? "text-white" : "text-black"
              } text-sm capitalize px-2`}
            >
              <span>{formattedPath}</span>
            </div>
          </div>

          <div className="flex items-center justify-center gap-2 pt-4 lg:pt-0 pb-2 lg:pb-0">
            <input
              type="text"
              placeholder="Search"
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
              className={`${
                isDarkMode
                  ? "bg-[#292929] border border-gray-500 text-white"
                  : "bg-gray-100 border border-gray-300 text-black"
              } px-4 py-2 border border-gray-300 rounded-lg`}
            />
          </div>
        </div>

        <h2 className="text-2xl font-semibold mb-4">Available Assignments</h2>

        <div className="max-w-[320px] md:max-w-[700px] lg:max-w-full overflow-x-auto pb-4">
          <table className="min-w-full divide-y divide-gray-200">
            <thead
              className={`${
                isDarkMode ? "bg-[#1e1e1e] text-white" : "bg-white text-black"
              }`}
            >
              <tr>
                <th
                  onClick={() => toggleSortDirection("Title")}
                  className="cursor-pointer px-4 py-2 text-center text-base font-medium text-gray-500 uppercase tracking-wider"
                >
                  Title
                  {sortColumn === "Title" && (
                    <span className="text-sm px-1 items-center text-blue-400">
                      {sortDirection === "asc" ? "▲" : "▼"}
                    </span>
                  )}
                </th>
                <th
                  onClick={() => toggleSortDirection("Description")}
                  className="cursor-pointer px-4 py-2 text-center text-base font-medium text-gray-500 uppercase tracking-wider"
                >
                  Description
                  {sortColumn === "Description" && (
                    <span className="text-sm px-1 items-center text-blue-400">
                      {sortDirection === "asc" ? "▲" : "▼"}
                    </span>
                  )}
                </th>
                <th className="cursor-pointer justify-center flex items-center px-4 py-2 text-base font-medium text-gray-500 uppercase tracking-wider">
                  Assignment
                </th>
              </tr>
            </thead>
            <tbody
              className={`${
                isDarkMode ? "text-white" : "text-black"
              } divide-y divide-gray-200`}
            >
              {contents.length > 0 &&
                contents.map((content) => (
                  <tr
                    className={`${
                      isDarkMode
                        ? "hover:bg-slate-600 rounded-[5px]"
                        : "hover:bg-slate-200 rounded-[5px]"
                    }`}
                    key={content.id}
                  >
                    <td
                      onClick={() => handleOpen(content.url)}
                      className="cursor-pointer px-4 py-2 whitespace-nowrap text-base text-center"
                    >
                      <h2 className="text-[16px]">{content.title}</h2>
                    </td>
                    <td
                      onClick={() => handleOpen(content.url)}
                      className="cursor-pointer px-4 py-2 text-base break-words text-center"
                    >
                      {content.description}
                    </td>
                    <td
                      className="px-4 py-2 break-words text-base text-[#2083D8] text-center cursor-pointer"
                      onClick={() => handleOpen(content.url)}
                    >
                      Open
                    </td>
                  </tr>
                ))}
              {!contents.length && !loading && (
                <tr>
                  <td
                    colSpan="5"
                    className={`${
                      isDarkMode ? "text-white" : "text-gray-500"
                    } px-2 py-5 text-center text-lg text-gray-500`}
                  >
                    No Assignments Available
                  </td>
                </tr>
              )}
              {error && (
                <tr>
                  <td
                    colSpan="5"
                    className={`${
                      isDarkMode ? "text-white" : "text-gray-500"
                    } px-2 py-5 text-center text-lg text-gray-500`}
                  >
                    {error}
                  </td>
                </tr>
              )}
              {cheatError && (
                <tr>
                  <td
                    colSpan="5"
                    className={`${
                      isDarkMode ? "text-white" : "text-gray-500"
                    } px-2 py-5 text-center text-lg text-gray-500`}
                  >
                    {cheatError}
                    <div className="p-4">
                      <button
                        onClick={() => (window.location.href = "/courses")}
                        className={` ${
                          isDarkMode
                            ? "bg-customRed text-white"
                            : "bg-customBlue text-white"
                        } p-3 rounded-[10px]`}
                      >
                        Go Back
                      </button>
                    </div>
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
        <div className="flex items-center justify-end py-4 text-black">
          <label
            htmlFor="pageSize"
            className={`${
              isDarkMode ? "text-white" : "text-gray-700"
            } mr-2 text-sm`}
          >
            Assignment per page:
          </label>
          <select
            id="pageSize"
            value={pageSize}
            onChange={(e) => {
              setPageSize(Number(e.target.value));
              setPage(1);
            }}
            className="px-4 py-2 border border-gray-300 rounded-lg"
          >
            {[10, 20, 30, 50].map((size) => (
              <option key={size} value={size}>
                {size}
              </option>
            ))}
          </select>
        </div>

        {/* Pagination Controls (Bottom) */}
        <div className="flex justify-center pt-8">
          <div>
            {Array.from({ length: totalPages }, (_, index) => (
              <button
                key={index + 1}
                onClick={() => setPage(index + 1)}
                className={`px-4 py-2 ${
                  page === index + 1
                    ? "bg-customBlue text-white"
                    : "bg-gray-300"
                } rounded-[5px] mx-1`}
              >
                {index + 1}
              </button>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}

export default CourseContentPage;
