import React, { useState, useEffect, useContext, useCallback } from "react";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { ClipLoader } from "react-spinners";

import axiosInstance from "../../../Context/axiosInstance.js";
import { DarkModeContext } from "../../../Context/DarkModeContext.jsx";

function EditAnnouncementForm({ announcementId, onClose }) {
  const { isDarkMode } = useContext(DarkModeContext);

  const [announcement, setAnnouncement] = useState(null);
  const [courses, setCourses] = useState([]);
  const [selectedCourses, setSelectedCourses] = useState([]);
  const [newTitle, setNewTitle] = useState("");
  const [newDescription, setNewDescription] = useState("");
  const [loading, setLoading] = useState(false);
  const [loadingEdit, setLoadingEdit] = useState(false);
  const [showForm, setShowForm] = useState(true);
  const [searchQuery, setSearchQuery] = useState("");

  const fetchAnnouncement = useCallback(async () => {
    try {
      const announcementResponse = await axiosInstance.get(
        `/announcements/${announcementId}`
      );
      setAnnouncement(announcementResponse.data);
      setNewTitle(announcementResponse.data.title);
      setNewDescription(announcementResponse.data.description);
      setSelectedCourses(announcementResponse.data.coursesIds || []);
    } catch (error) {
      toast.error("Error fetching announcement data");
    }
  }, [announcementId]); // Only depend on announcementId

  useEffect(() => {
    if (announcementId) {
      fetchAnnouncement();
    }
  }, [announcementId, fetchAnnouncement]); // Add fetchAnnouncement as a dependency

  // Wrap fetchCourses in useCallback to make it stable across renders
  const fetchCourses = useCallback(async () => {
    setLoading(true);
    try {
      const response = await axiosInstance.get(
        `/courses?page=1&pageSize=99999&sortColumn=Name&sortDirection=asc&search=${encodeURIComponent(
          searchQuery
        )}`
      );
      setCourses(response.data.response);
    } catch (error) {
      toast.error("Error fetching courses");
    } finally {
      setLoading(false);
    }
  }, [searchQuery]); // Only depend on searchQuery

  useEffect(() => {
    fetchCourses();
  }, [fetchCourses]); // Now fetchCourses is stable and can be safely added as a dependency

  const handleCheckboxChange = (courseId) => {
    setSelectedCourses((prevSelectedCourses) => {
      const newSelectedCourses = prevSelectedCourses.includes(courseId)
        ? prevSelectedCourses.filter((id) => id !== courseId)
        : [...prevSelectedCourses, courseId];
      return newSelectedCourses;
    });
  };

  const saveEdit = async () => {
    if (newTitle.trim() === "" || newDescription.trim() === "") {
      toast.error("Both Title and Description must be filled.");
      return;
    }

    try {
      setLoadingEdit(true);
      await axiosInstance.put(`/announcements/${announcementId}`, {
        title: newTitle,
        description: newDescription,
        coursesIds: selectedCourses,
      });

      toast.success("Announcement updated successfully!");
      onClose();
    } catch (error) {
      toast.error("Error updating announcement.");
    } finally {
      setLoadingEdit(false);
    }
  };

  const closeForm = () => {
    setShowForm(false);
    setTimeout(() => {
      onClose(); // Trigger onClose after animation finishes
    }, 300); // Match the animation duration
  };

  return (
    <div
      className={`fixed top-[100px] right-5 min-h-[750px] w-[500px] z-[1000] flex justify-end ${
        showForm ? "slide-in" : "slide-out"
      }`}
    >
      <div
        className={`${
          isDarkMode ? "bg-[#292929]" : "bg-white"
        } max-w-[500px] min-w-[400px] h-full rounded-[30px] p-6 shadow-xl`}
      >
        {announcement ? (
          <div>
            <div className="w-full mb-6 text-center bg-blue-500 rounded-full text-white py-4">
              <p className="text-lg">Edit Announcement</p>
            </div>
            <input
              type="text"
              value={newTitle}
              onChange={(e) => setNewTitle(e.target.value)}
              placeholder="Title"
              className={`${
                isDarkMode
                  ? "bg-[#292929] border border-gray-600"
                  : "bg-gray-100 border border-gray-300"
              } w-full p-4 rounded-lg mb-4`}
            />
            <textarea
              value={newDescription}
              onChange={(e) => setNewDescription(e.target.value)}
              placeholder="Description"
              className={`${
                isDarkMode
                  ? "bg-[#292929] border border-gray-600"
                  : "bg-gray-100 border border-gray-300"
              } w-full min-h-[60px] max-h-[200px] p-4 rounded-lg`}
              rows="4"
            />

            <div
              className={`max-h-[400px] overflow-y-auto custom-scrollbar ${
                isDarkMode
                  ? "border-2 border-gray-600"
                  : "border-2 border-gray-300"
              } p-3 mt-5 rounded-md`}
            >
              <div className="flex flex-col lg:flex-row lg:justify-between pb-2">
                <input
                  type="text"
                  placeholder="Search"
                  value={searchQuery}
                  onChange={(e) => setSearchQuery(e.target.value)}
                  className={`${
                    isDarkMode
                      ? "bg-[#292929] border border-gray-600"
                      : "bg-gray-100 border border-gray-300"
                  } w-full px-4 py-2 rounded-lg`}
                />
              </div>
              {courses.length > 0 ? (
                courses.map((course) => (
                  <div key={course.id} className="flex items-center gap-2 p-1">
                    <input
                      type="checkbox"
                      id={`course-${course.id}`}
                      checked={selectedCourses.includes(course.id)} // Pre-select based on selectedCourses
                      onChange={() => handleCheckboxChange(course.id)}
                    />
                    <span className="text-sm">
                      {course.name}{" "}
                      {course.gradeLevel === "Custom" && (
                        <span className="text-red-500">* Custom Course *</span>
                      )}
                    </span>
                  </div>
                ))
              ) : (
                <div className="text-gray-500">No courses found</div>
              )}
            </div>

            {/* Save or Cancel Buttons */}
            <div className="flex justify-center gap-4 my-4">
              {loading ? (
                <ClipLoader color="#2264E5" size={30} />
              ) : (
                <>
                  <button
                    onClick={saveEdit}
                    className={`${
                      isDarkMode
                        ? "border-2 border-green-600 hover:bg-green-600 ease-in-out transition-all duration-300"
                        : "bg-green-600 text-white hover:bg-green-400 ease-in-out transition-all duration-300"
                    } px-6 py-2 rounded-lg`}
                  >
                    {loadingEdit ? "Saving..." : "Save Changes"}
                  </button>
                  <button
                    onClick={closeForm} // Close with slide-out effect
                    className="px-6 py-2 bg-gray-600 text-white rounded-lg"
                  >
                    Cancel
                  </button>
                </>
              )}
            </div>
          </div>
        ) : (
          <div className="flex justify-center items-center h-full gap-4 my-4">
            <ClipLoader color="#2264E5" size={50} />
          </div>
        )}
      </div>
    </div>
  );
}

export default EditAnnouncementForm;
