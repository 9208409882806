import React, { useState, useEffect } from "react";
import { IoIosCloseCircleOutline } from "react-icons/io";
import axiosInstance from "../../Context/axiosInstance";
import { ClipLoader } from "react-spinners";

function ContentModal({ isModalOpen, closeModal, type }) {
  const [newContent, setNewContent] = useState([
    { title: "", description: "", url: "" },
  ]);
  const [courses, setCourses] = useState([]);
  const [selectedCourses, setSelectedCourses] = useState([]);
  const [formErrors, setFormErrors] = useState({});
  const [error, setError] = useState("");
  const [searchQuery, setSearchQuery] = useState("");
  const [filteredCourses, setFilteredCourses] = useState(courses);
  const [loading, setLoading] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      setError("");
      try {
        const response = await axiosInstance.get(
          `/courses?page=1&pageSize=10000&sortColumn=Name&sortDirection=asc&search=${encodeURIComponent(
            searchQuery
          )}`
        );
        const fetchedCourses = response.data.response;
        setCourses(fetchedCourses);
        setFilteredCourses(fetchedCourses);
      } catch (error) {
        setError(error.message);
      } finally {
        setLoading(false);
      }
    };
    fetchData();
  }, [searchQuery]);

  const handleCourseSelect = (courseId) => {
    setSelectedCourses((prevSelectedCourses) => {
      if (prevSelectedCourses.includes(courseId)) {
        return prevSelectedCourses.filter((id) => id !== courseId);
      } else {
        return [...prevSelectedCourses, courseId];
      }
    });
  };

  const handleFormChange = (index, e) => {
    const values = [...newContent];
    values[index][e.target.name] = e.target.value;
    setNewContent(values);
  };

  const handleSubmit = async () => {
    if (isSubmitting) return;

    setIsSubmitting(true);

    const newErrors = {};
    newContent.forEach((content, index) => {
      if (!content.title || content.title.trim() === "") {
        newErrors[`content[${index}].title`] = "Title is required.";
      }
      if (!content.description || content.description.trim() === "") {
        newErrors[`content[${index}].description`] = "Description is required.";
      }
      if (type !== "material" && (!content.url || content.url.trim() === "")) {
        newErrors[`content[${index}].url`] = "URL is required.";
      } else if (
        type !== "material" &&
        !/^(https?:\/\/)?([a-zA-Z0-9-]+\.)+[a-zA-Z]{2,}(\/.*)?$/.test(
          content.url
        )
      ) {
        newErrors[`content[${index}].url`] = "URL is invalid.";
      }
    });

    setFormErrors(newErrors);
    if (Object.keys(newErrors).length > 0) {
      setError("Please fix the errors above.");
      setIsSubmitting(false);
      return;
    }

    if (selectedCourses.length === 0) {
      setError("Please select at least one course.");
      setIsSubmitting(false);
      return;
    }

    // Assuming you are submitting a single video object, not an array of videos
    const requestBody = {
      [type]: {
        title: newContent[0].title, // Take the first video title
        description: newContent[0].description, // Take the first video description
        url: newContent[0].url, // Take the first video URL
      },
      courseIds: selectedCourses, // List of selected course IDs
    };

    try {
      await axiosInstance.post(`/courses/${type}/courses`, requestBody);
      setNewContent([{ title: "", description: "", url: "" }]);
      setFormErrors({});
      closeModal();
    } catch (error) {
      const errorMessage = error.response?.data?.errors?.[0]?.reason;
      setError(errorMessage);
    } finally {
      setIsSubmitting(false); // Reset submitting state
    }
  };

  return (
    <>
      {isModalOpen && (
        <div className="fixed inset-0 flex items-center justify-center bg-gray-900 bg-opacity-50 z-50">
          <div className="w-[450px] bg-white text-black p-4 rounded-lg shadow-xl overflow-y-auto relative">
            <IoIosCloseCircleOutline
              onClick={closeModal}
              className="absolute top-3 right-3 text-2xl cursor-pointer text-gray-500 hover:text-red-500"
            />
            <h2 className="text-xl font-semibold text-center mt-2 mb-4">
              Add New {type.charAt(0).toUpperCase() + type.slice(1)}
            </h2>

            {/* Search Bar */}
            <div className="flex justify-center mb-3">
              <input
                type="text"
                value={searchQuery}
                onChange={(e) => setSearchQuery(e.target.value)}
                placeholder="Search for courses"
                className="w-[300px] p-2 rounded-full focus:outline-none text-center border-2 border-gray-300"
              />
            </div>

            {/* Course Selection with Checkboxes */}
            <div className="relative p-3 overflow-y-auto custom-scrollbar min-h-[210px] max-h-[210px] border-2 border-gray-300 rounded-md">
              {loading ? (
                <div className="flex justify-center items-center w-full h-full absolute inset-0 z-10">
                  <ClipLoader color="#2264E5" size={50} />
                </div>
              ) : filteredCourses.length > 0 ? (
                filteredCourses.map((course) => (
                  <div key={course.id} className="flex items-center gap-2 p-1">
                    <input
                      type="checkbox"
                      checked={selectedCourses.includes(course.id)}
                      onChange={() => handleCourseSelect(course.id)}
                      className="w-4 h-4"
                    />
                    <span className="text-sm">
                      {course.name}{" "}
                      {course.gradeLevel === "Custom" && (
                        <span className="text-red-500">* Custom Course *</span>
                      )}
                    </span>
                  </div>
                ))
              ) : (
                <div className="text-gray-500 absolute inset-0 flex justify-center items-center">
                  No courses found
                </div>
              )}
            </div>

            <form className="flex flex-col gap-3 pt-4">
              {newContent.map((content, index) => (
                <div
                  key={index}
                  className="flex flex-col gap-2 border-b border-gray-300 pb-4"
                >
                  <input
                    type="text"
                    name="title"
                    placeholder={`${
                      type.charAt(0).toUpperCase() + type.slice(1)
                    } Title`}
                    value={content.title}
                    onChange={(e) => handleFormChange(index, e)}
                    className="px-3 py-2 text-sm rounded-md border border-gray-300 focus:outline-none"
                    required
                  />
                  {formErrors[`content[${index}].title`] && (
                    <p className="text-red-500 text-sm">
                      {formErrors[`content[${index}].title`]}
                    </p>
                  )}

                  <input
                    type="text"
                    name="description"
                    placeholder={`${
                      type.charAt(0).toUpperCase() + type.slice(1)
                    } Description`}
                    value={content.description}
                    onChange={(e) => handleFormChange(index, e)}
                    className="px-3 py-2 text-sm rounded-md border border-gray-300 focus:outline-none"
                    required
                  />
                  {formErrors[`content[${index}].description`] && (
                    <p className="text-red-500 text-sm">
                      {formErrors[`content[${index}].description`]}
                    </p>
                  )}

                  <input
                    type="text"
                    name="url"
                    placeholder={`${
                      type.charAt(0).toUpperCase() + type.slice(1)
                    } URL`}
                    value={content.url}
                    onChange={(e) => handleFormChange(index, e)}
                    className="px-3 py-2 text-sm rounded-md border border-gray-300 focus:outline-none"
                    required
                  />
                  {formErrors[`content[${index}].url`] && (
                    <p className="text-red-500 text-sm">
                      {formErrors[`content[${index}].url`]}
                    </p>
                  )}
                </div>
              ))}
            </form>

            {error && (
              <div className="bg-red-100 text-red-500 p-2 mt-2 rounded-md">
                {error}
              </div>
            )}

            <div className="flex justify-center mt-4">
              <button
                onClick={handleSubmit}
                disabled={isSubmitting}
                className="bg-[#2264E5] hover:bg-sky-500 w-full text-white py-2 px-6 rounded-full shadow-lg"
                type="button"
              >
                {isSubmitting ? (
                  <span className="dots">Submitting</span>
                ) : (
                  "Submit"
                )}
              </button>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default ContentModal;
