import React, { useState, useEffect, useContext, useCallback } from "react";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
  IoMdCart,
  IoIosCloseCircleOutline,
  IoIosRemoveCircleOutline,
  IoMdAdd,
} from "react-icons/io";
import { FaVideo, FaClipboardList } from "react-icons/fa";
import { FaBook } from "react-icons/fa6";
import { GiConfirmed } from "react-icons/gi";
import { MdShoppingCartCheckout, MdQuiz } from "react-icons/md";
import CryptoJS from "crypto-js";
import { ClipLoader } from "react-spinners";

import axiosInstance from "../../Context/axiosInstance";
import { DarkModeContext } from "../../Context/DarkModeContext.jsx";

const CataloguePage = () => {
  const { isDarkMode } = useContext(DarkModeContext);

  // STATE HOOKS
  const [cartItems, setCartItems] = useState([]);
  const [cartItemCount, setCartItemCount] = useState(0);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [items, setItems] = useState([]);
  const [selectedTab, setSelectedTab] = useState("videos");
  const [fadeIn, setFadeIn] = useState(false);
  const [isClosing, setIsClosing] = useState(false);

  const userId = localStorage.getItem("userId");
  const [balance, setBalance] = useState(0);
  const [loadingBalance, setLoadingBalance] = useState(false);
  const [addModalOpen, setAddModalOpen] = useState(false);
  const [formErrors, setFormErrors] = useState({});
  const [formData, setFormData] = useState({
    courseImageUrl: "",
    courseName: "",
  });

  const [animationClass, setAnimationClass] = useState("");
  const [loadingaddCourse, setLoadingaddCourse] = useState(false);

  // Pagination states
  const [sortColumn, setSortColumn] = useState("Title");
  const [sortDirection, setSortDirection] = useState("asc");
  const [searchQuery, setSearchQuery] = useState("");
  const [totalPages, setTotalPages] = useState(1);
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);

  // utility function to decrypt data
  const decryptData = (encryptedData) => {
    const bytes = CryptoJS.AES.decrypt(
      encryptedData,
      process.env.REACT_APP_SECRET_KEY
    );
    return bytes.toString(CryptoJS.enc.Utf8);
  };

  useEffect(() => {
    const fetchBalance = async () => {
      setLoadingBalance(true);
      try {
        const response = await axiosInstance.get(
          `/users/${decryptData(userId)}/wallet`
        );
        setBalance(response.data.balance);
      } finally {
        setLoadingBalance(false);
      }
    };
    fetchBalance();
  }, [userId]);

  // Function to fetch data based on selected tab
  const fetchItems = useCallback(async () => {
    setIsLoading(true);
    try {
      const endpoint = `/catalogs/${selectedTab}?page=${page}&pageSize=${pageSize}&sortColumn=${sortColumn}&sortDirection=${sortDirection}&search=${encodeURIComponent(
        searchQuery
      )}`;
      const response = await axiosInstance.get(endpoint);

      if (response.data && response.data.response) {
        // Append the new items only when the page is valid
        setItems(response.data.response);

        const calculatedTotalPages = Math.ceil(
          response.data.totalCount / pageSize
        );
        setTotalPages(calculatedTotalPages);

        // Adjust page if it exceeds total pages
        if (page > calculatedTotalPages) {
          setPage(calculatedTotalPages);
        }
      }
    } catch (error) {
    } finally {
      setIsLoading(false);
    }
  }, [selectedTab, page, pageSize, searchQuery, sortColumn, sortDirection]);

  useEffect(() => {
    fetchItems();
  }, [fetchItems]);

  useEffect(() => {
    // Reset to page 1 whenever the search query changes
    setPage(1);
  }, [searchQuery]);

  useEffect(() => {
    try {
      const storedCartItems =
        JSON.parse(localStorage.getItem("cartItems")) || [];
      if (Array.isArray(storedCartItems)) {
        setCartItems(storedCartItems);
        setCartItemCount(storedCartItems.length);
      } else {
        localStorage.removeItem("cartItems"); // Clear invalid data
      }
    } catch (error) {
      localStorage.removeItem("cartItems"); // Clear invalid data
    }
  }, []);

  const categoryTypes = {
    assignments: "assignment",
    materials: "material",
    quizzes: "quiz",
    videos: "video",
  };

  const addToCart = (item) => {
    const type = categoryTypes[selectedTab]; // Get the type from the selected tab

    if (type) {
      const itemWithType = {
        ...item,
        type: type, // Add the type to the item
      };

      // Check if the item already exists in the cart based on id and type
      const itemExists = cartItems.some(
        (cartItem) =>
          cartItem.id === itemWithType.id && cartItem.type === itemWithType.type
      );

      if (itemExists) {
        toast.info("This item is already in the cart.", {
          position: "top-center",
          autoClose: 3000,
          hideProgressBar: false,
          pauseOnHover: false,
        });
        return; // Stop the function if the item already exists
      }

      // Add the item to the cart if it doesn't exist
      setCartItems((prev) => {
        const updatedCart = [...prev, itemWithType];
        localStorage.setItem("cartItems", JSON.stringify(updatedCart));
        return updatedCart;
      });

      setCartItemCount(cartItemCount + 1);
      const truncatedName =
        item.title.length > 20 ? item.title.slice(0, 20) + "..." : item.title;
      toast.success(`${truncatedName} added to cart`, {
        position: "top-center",
        autoClose: 3000,
        hideProgressBar: false,
        pauseOnHover: false,
      });
    } else {
      toast.error("Invalid item type. Please try again.", {
        position: "top-center",
        autoClose: 3000,
        hideProgressBar: false,
        pauseOnHover: false,
      });
    }
  };

  const deleteFromCart = (id, type) => {
    // Filter the cart items to remove the specific item based on both id and type
    const updatedCart = cartItems.filter(
      (item) => item.id !== id || item.type !== type
    );

    setCartItems(updatedCart);
    localStorage.setItem("cartItems", JSON.stringify(updatedCart));
    setCartItemCount(updatedCart.length);
  };

  const handleFormChange = (e) => {
    const { name, value } = e.target;

    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleCheckout = async () => {
    const totalCartPrice = cartItems.reduce((acc, item) => acc + item.price, 0);
    if (balance >= totalCartPrice) {
      // Check if the user has enough balance
      openAddCourseModal(true); // Open the modal
      setAnimationClass("slide-in"); // Optional: Add animation to the modal
    } else {
      toast.error("Insufficient balance", {
        position: "top-center",
        autoClose: 3000,
        hideProgressBar: false,
        pauseOnHover: false,
      });
    }
  };

  const openAddCourseModal = () => {
    setAddModalOpen(true); // Open the modal
    setAnimationClass("slide-in"); // Set animation class if needed
  };

  const handleAdd = async () => {
    if (!formData.courseName) {
      setFormErrors({
        courseName: "Course name is required",
      });
      return;
    }

    setLoadingaddCourse(true); // Start loading

    // Initialize empty arrays for assignments, materials, quizzes, and videos
    let assignmentsIds = [];
    let materialsIds = [];
    let quizzesIds = [];
    let videosIds = [];

    // Calculate the total price of items in the cart
    let totalAmount = 0;
    cartItems.forEach((item) => {
      if (item.id > 0) {
        totalAmount += item.price; // Assuming each item has a `price` field
        // Only include items that have a positive ID
        if (item.type === "video") {
          videosIds.push(item.id);
        } else if (item.type === "assignment") {
          assignmentsIds.push(item.id);
        } else if (item.type === "material") {
          materialsIds.push(item.id);
        } else if (item.type === "quiz") {
          quizzesIds.push(item.id);
        }
      }
    });

    // Check if there are cart items
    if (cartItems.length === 0) {
      toast.error("Your cart is empty. Please add items to your cart.", {
        position: "top-center",
        autoClose: 3000,
        hideProgressBar: false,
        pauseOnHover: false,
      });
      return;
    }

    // Check if the user has enough balance to make the purchase
    if (balance < totalAmount) {
      toast.error("You don't have enough balance to purchase this course.", {
        position: "top-center",
        autoClose: 3000,
      });
      return;
    }

    // Deduct the total amount from the user's balance locally
    const newBalance = balance - totalAmount;

    // Update the balance state locally
    setBalance(newBalance); // Assuming you are using a state hook like useState for balance

    // Construct the payload for the POST request
    const payload = {
      name: formData.courseName,
      imageUrl:
        "https://img.freepik.com/free-vector/blue-curve-background_53876-113112.jpg?semt=ais_hybrid",
    };

    // Only add arrays if they are not empty (i.e., contain valid IDs)
    if (assignmentsIds.length > 0) payload.assignmentsIds = assignmentsIds;
    if (materialsIds.length > 0) payload.materialsIds = materialsIds;
    if (quizzesIds.length > 0) payload.quizzesIds = quizzesIds;
    if (videosIds.length > 0) payload.videosIds = videosIds;

    try {
      const response = await axiosInstance.post(
        "/courses/purchase/custom",
        payload,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      if (response.status === 200) {
        toast.success("Course Purchased Successfully!", {
          position: "top-center",
          autoClose: 3000,
        });
        handleCloseAddModal();
        closeModal();
      } else {
        toast.error("Failed to add course, no data returned from the server.", {
          position: "top-center",
          autoClose: 3000,
        });
      }
    } catch (error) {
      toast.error("Something went wrong. Please try again.", {
        position: "top-center",
        autoClose: 3000,
      });
    }
  };

  const handleCloseAddModal = () => {
    setAddModalOpen(false);
    setFormErrors({});
    setFormData({
      courseName: "",
      courseImageUrl: "",
    });
  };

  const handlePageChange = (newPage) => {
    if (newPage >= 1 && newPage <= totalPages) {
      setPage(newPage);
    }
  };

  // control sorting direction
  const toggleSortDirection = (column) => {
    if (sortColumn === column) {
      setSortDirection((prev) => (prev === "asc" ? "desc" : "asc"));
    } else {
      setSortColumn(column);
      setSortDirection("asc");
    }
  };

  // Toast notifications
  useEffect(() => {
    if (isModalOpen) {
      setFadeIn(true); // Trigger fade-in effect
    }
  }, [isModalOpen]);

  // Handle tab selection
  const handleTabSelect = (tab) => {
    setSelectedTab(tab);
    setSearchQuery("");
  };

  // Open the cart modal
  const openModal = () => {
    setIsModalOpen(true);
    document.body.style.overflow = "hidden";
  };

  const closeModal = () => {
    setIsClosing(true);
    setTimeout(() => {
      setIsModalOpen(false);
      setIsClosing(false);
      setFadeIn(false);
    }, 300);
    document.body.style.overflow = "visible";
  };

  return (
    <div
      className={`flex flex-col lg:flex-row lg:items-start ${
        isDarkMode ? "bg-[#181818]" : "bg-customBlue"
      } p-4 lg:p-8 min-h-[200vh] md:min-h-screen flex-1`}
    >
      <div
        className={`${
          isDarkMode ? "bg-[#1e1e1e]" : "bg-white"
        } p-4 lg:p-8 mt-[40px] lg:mt-0 rounded-lg shadow-xl flex-1`}
      >
        <div className="flex-1 flex flex-col mt-3 lg:mr-10">
          <h2 className="text-3xl font-semibold text-center pb-4">
            Customize Your Learnings
          </h2>
          {/* Custom Tab Bar */}
          <div
            className={`flex justify-center items-center ${
              isDarkMode ? " text-white" : " text-black"
            }`}
          >
            <div className="relative flex items-center">
              <input
                type="radio"
                id="rd-videos"
                name="radio"
                className="hidden"
                checked={selectedTab === "videos"}
                onChange={() => handleTabSelect("videos")}
              />
              <label
                htmlFor="rd-videos"
                className={`cursor-pointer px-4 py-2 ${
                  selectedTab === "videos" ? "text-blue-400" : "text-gray-600"
                }`}
              >
                <span>Videos</span>
              </label>

              <input
                type="radio"
                id="rd-materials"
                name="radio"
                className="hidden"
                checked={selectedTab === "materials"}
                onChange={() => handleTabSelect("materials")}
              />
              <label
                htmlFor="rd-materials"
                className={`cursor-pointer px-4 py-2 ${
                  selectedTab === "materials"
                    ? "text-blue-400"
                    : "text-gray-600"
                }`}
              >
                <span>Materials</span>
              </label>

              <input
                type="radio"
                id="rd-quizzes"
                name="radio"
                className="hidden"
                checked={selectedTab === "quizzes"}
                onChange={() => handleTabSelect("quizzes")}
              />
              <label
                htmlFor="rd-quizzes"
                className={`cursor-pointer px-4 py-2 ${
                  selectedTab === "quizzes" ? "text-blue-400" : "text-gray-600"
                }`}
              >
                <span>Quizzes</span>
              </label>

              <input
                type="radio"
                id="rd-assignments"
                name="radio"
                className="hidden"
                checked={selectedTab === "assignments"}
                onChange={() => handleTabSelect("assignments")}
              />
              <label
                htmlFor="rd-assignments"
                className={`cursor-pointer px-4 py-2 ${
                  selectedTab === "assignments"
                    ? "text-blue-400"
                    : "text-gray-600"
                }`}
              >
                <span>Assignments</span>
              </label>

              {/* Underline bar */}
              <div
                className={`absolute bottom-0 left-0 h-1 bg-blue-400 transition-transform duration-300 ease-in-out`}
                style={{
                  transform: `translateX(${
                    selectedTab === "videos"
                      ? "-11px"
                      : selectedTab === "materials"
                      ? "80px"
                      : selectedTab === "quizzes"
                      ? "175px"
                      : "284px"
                  })`,
                  width: "25%",
                }}
              ></div>
            </div>
          </div>

          <div className="flex flex-col lg:flex-row lg:gap-4 w-full justify-center lg:justify-between items-center">
            {/* Search Section on the Left */}
            <div className="flex justify-start items-center mt-6 w-[500px] lg:w-[300px]">
              <input
                type="text"
                placeholder="Search..."
                value={searchQuery}
                onChange={(e) => setSearchQuery(e.target.value)}
                className={`${
                  isDarkMode ? "bg-[#292929]" : "bg-gray-100"
                } px-4 py-2 text-center lg:text-left border border-gray-300 rounded-lg w-full`}
              />
            </div>

            {/* Buttons Section on the Right */}
            <div className="flex gap-4 mt-4 lg:mt-0 pb-3 lg:pb-0">
              {/* Fill Wallet Button */}
              <div className="flex justify-center items-center">
                <a
                  href="https://docs.google.com/forms/d/1RYLIZrvHawznECvXRxh_yiTUsUK__rssOXXeTR8sOkA/edit"
                  target="_blank"
                  rel="noreferrer"
                  className={`${
                    isDarkMode
                      ? "border-[1px] border-customRed bg-gradient-to-r from-[#181818] via-[#222222] to-[#383838] text-white rounded-full"
                      : "bg-gradient-to-r from-blue-200 via-blue-400 to-blue-600 text-white rounded-md"
                  } px-6 py-2 shimmer-effect transform transition-transform duration-200 ease-in-out active:scale-95`}
                >
                  Fill Wallet
                </a>
              </div>

              {/* Balance Display */}
              <div
                className={`flex justify-center items-center ${
                  isDarkMode
                    ? "bg-customRed text-white"
                    : "bg-blue-500 text-white"
                } text-sm rounded-[10px] p-3`}
              >
                <span>
                  Balance:{" "}
                  {loadingBalance ? (
                    <span className="font-bold">...</span>
                  ) : (
                    <span className="font-bold"> {balance} EGP</span>
                  )}
                </span>
              </div>
            </div>
          </div>

          <div className="overflow-x-auto mt-6 min-h-[650px]">
            <table
              className={`min-w-full table-auto border-collapse ${
                isDarkMode ? "bg-[#1e1e1e] text-white" : "bg-white text-black"
              }`}
            >
              <thead>
                <tr className="border-b">
                  <th
                    onClick={() => toggleSortDirection("Title")}
                    className="cursor-pointer px-6 py-3 text-left lg:min-w-[170px]"
                  >
                    Title
                    {sortColumn === "Title" && (
                      <span className="text-sm px-1 items-center text-blue-400">
                        {sortDirection === "asc" ? "▲" : "▼"}
                      </span>
                    )}
                  </th>
                  <th className="px-6 py-3 text-left">Description</th>
                  <th
                    onClick={() => toggleSortDirection("Price")}
                    className="cursor-pointer px-6 py-3 text-left"
                  >
                    Price
                    {sortColumn === "Price" && (
                      <span className="text-sm px-1 items-center text-blue-400 ease-in-out">
                        {sortDirection === "asc" ? "▲" : "▼"}
                      </span>
                    )}
                  </th>
                  <th className="px-6 py-3 text-left"></th>
                </tr>
              </thead>
              <tbody>
                {isLoading ? (
                  <tr>
                    <td colSpan="5" className="relative text-center py-4">
                      <div className="absolute top-[350px] inset-0 flex justify-center items-center bg-opacity-50 z-10">
                        <ClipLoader color="#2264E5" size={50} />
                      </div>
                    </td>
                  </tr>
                ) : items.length === 0 ? (
                  <tr>
                    <td colSpan="5" className="text-center py-4 text-gray-200">
                      Items not found
                    </td>
                  </tr>
                ) : (
                  items.map((item) => (
                    <tr
                      key={`${item.id}-${item.type}`}
                      className="border-b text-left"
                    >
                      <td className="px-6 py-4 lg:min-w-[400px] lg:max-w-[400px] lg:truncate">
                        {item.title}
                      </td>
                      <td className="px-6 py-4 lg:min-w-[400px] lg:max-w-[400px] lg:truncate">
                        {item.description}
                      </td>
                      <td className="px-6 py-4">{item.price.toFixed(2)} EGP</td>
                      <td className="px-6 py-4">
                        <button
                          onClick={() => addToCart(item)}
                          className={`bg-customBlue text-white hover:bg-blue-800 px-6 py-2 rounded-md`}
                        >
                          Add to Cart
                        </button>
                        {/* <button
                          onClick={() => addToCart(item)}
                          className={`bg-customBlue text-white hover:bg-blue-800 px-6 py-2 ${
                            cartItems.some(
                              (cartItem) => cartItem.id === item.id
                            ) && "cursor-not-allowed opacity-50"
                          } rounded-md`}
                          disabled={cartItems.some(
                            (cartItem) => cartItem.id === item.id
                          )}
                        >
                          {cartItems.some((cartItem) => cartItem.id === item.id)
                            ? "In the Cart"
                            : "Add to Cart"}
                        </button> */}
                      </td>
                    </tr>
                  ))
                )}
              </tbody>
            </table>
          </div>

          {/* Dropdown to choose items per page */}
          <div className="flex items-center justify-end py-4 text-black">
            <label
              htmlFor="pageSize"
              className={`${
                isDarkMode ? "text-white" : "text-gray-700"
              } mr-2 text-sm`}
            >
              Items per page:
            </label>
            <select
              id="pageSize"
              value={pageSize}
              onChange={(e) => {
                setPageSize(Number(e.target.value));
                setPage(1);
              }}
              className="px-4 py-2 border border-gray-300 rounded-lg"
            >
              {[10, 20, 30, 50].map((size) => (
                <option key={size} value={size}>
                  {size}
                </option>
              ))}
            </select>
          </div>

          {/* Pagination Controls */}
          <div className="mt-4 flex flex-col sm:flex-row justify-between items-center">
            <button
              onClick={() => handlePageChange(page - 1)}
              disabled={page <= 1}
              className={`px-4 py-2 bg-gray-300 text-black rounded-lg ${
                page <= 1
                  ? "opacity-50 cursor-not-allowed"
                  : "hover:bg-gray-400"
              }`}
            >
              Previous
            </button>
            <span className={"text-lg mt-2 sm:mt-0"}>
              Page {page} of {totalPages}
            </span>
            <button
              onClick={() => handlePageChange(page + 1)}
              disabled={page >= totalPages}
              className={`px-4 py-2 bg-gray-300 text-black rounded-lg ${
                page >= totalPages
                  ? "opacity-50 cursor-not-allowed"
                  : "hover:bg-gray-400"
              }`}
            >
              Next
            </button>
          </div>
        </div>

        {/* Cart Button */}
        <div className="relative">
          <button
            onClick={openModal}
            className={`${
              isDarkMode
                ? "bg-[#1e1e1e] border-[1px] border-customRed hover:bg-customRed text-white"
                : "bg-blue-500 text-white hover:bg-blue-600"
            } fixed top-4 right-4 lg:top-12 lg:right-12 p-3 rounded-full shadow-lg transition-all duration-300 w-14 h-14 flex items-center justify-center`}
          >
            <IoMdCart className="text-xl" />
            {cartItemCount > 0 && (
              <span
                className={`${
                  isDarkMode ? "bg-white text-black" : "bg-red-500 text-white"
                } absolute top-0 right-0 text-xs px-1.5 py-0.5 rounded-full`}
              >
                {cartItemCount}
              </span>
            )}
          </button>
        </div>

        {/* Modal */}
        {isModalOpen && (
          <div
            className={`fixed inset-0 bg-black bg-opacity-40 flex justify-center items-center z-50 transition-opacity duration-300 ${
              isClosing ? "opacity-0" : "opacity-100"
            }`}
          >
            <div
              className={`bg-white py-8 px-12 rounded-lg shadow-xl w-[500px] transition-opacity duration-300 ${
                fadeIn ? "opacity-100" : "opacity-0"
              }`}
            >
              <div className="flex justify-between mb-6">
                <h2 className="text-2xl font-semibold text-gray-800">
                  Your Cart ({cartItemCount})
                </h2>
                <button
                  onClick={closeModal}
                  className="relative text-lg font-bold text-gray-600 hover:text-gray-800"
                >
                  <IoIosCloseCircleOutline
                    className="text-gray-600 hover:text-red-700"
                    size={30}
                  />
                </button>
              </div>
              <div className="space-y-2 max-h-72 overflow-y-auto ml-1 pr-6">
                {cartItems.length > 0 ? (
                  cartItems.map((item) => (
                    <div
                      key={item.id}
                      className="flex justify-between items-center border-b pb-2"
                    >
                      <div className="flex items-center space-x-2">
                        {/* Conditionally render the icon based on the type */}
                        {item.type === "video" && (
                          <FaVideo size={20} className="text-gray-500" />
                        )}
                        {item.type === "assignment" && (
                          <FaClipboardList
                            size={20}
                            className="text-gray-500"
                          />
                        )}
                        {item.type === "material" && (
                          <FaBook size={20} className="text-gray-500" />
                        )}
                        {item.type === "quiz" && (
                          <MdQuiz size={20} className="text-gray-500" />
                        )}

                        {/* Title */}
                        <span className="text-gray-700">
                          {item.title.length > 25
                            ? item.title.slice(0, 25) + "..."
                            : item.title}
                        </span>
                      </div>

                      <div className="flex space-x-4 items-center">
                        <span className="text-gray-500">
                          {item.price.toFixed(2)} EGP
                        </span>
                        <button
                          onClick={() => deleteFromCart(item.id, item.type)}
                          className="text-red-600 hover:text-red-800"
                        >
                          <IoIosRemoveCircleOutline size={22} />
                        </button>
                      </div>
                    </div>
                  ))
                ) : (
                  <p className="text-gray-500">Your cart is empty.</p>
                )}
              </div>
              <div className="mt-6 border-t border-gray-700"></div>
              <div className="flex justify-between items-center pt-2">
                <strong className="text-gray-700 ">Total:</strong>
                <span className="text-xl font-semibold text-gray-800">
                  {cartItems
                    .reduce((acc, item) => acc + item.price, 0)
                    .toFixed(2)}{" "}
                  EGP
                </span>
              </div>
              {cartItems.length > 0 && (
                <button
                  className="w-full mt-4 bg-green-500 text-white py-2 rounded-md"
                  onClick={handleCheckout}
                >
                  <MdShoppingCartCheckout
                    size={22}
                    className="inline-block mr-2"
                  />
                  Proceed to Checkout
                </button>
              )}
            </div>
          </div>
        )}

        <ToastContainer position="top-center" />

        {/* MODAL FOR CUSTOM COURSE */}
        {addModalOpen && (
          <div className="fixed inset-0 flex items-center justify-center bg-gray-900 bg-opacity-50 z-50">
            <div
              className={`${animationClass} relative flex flex-col ${
                isDarkMode ? "bg-white text-black" : "bg-white text-black"
              } p-6 rounded-[35px] shadow-lg w-80`}
            >
              {/* Icon container */}
              <div className="absolute top-[-30px] left-1/2 transform -translate-x-1/2 flex justify-center items-center">
                <div className="bg-white w-[80px] h-[60px] shadow-md rounded-full border border-gray-300 flex justify-center items-center">
                  <IoMdAdd size={40} className="text-[#2264E5] animate-pulse" />
                </div>
              </div>
              {/* Close icon */}
              <IoIosCloseCircleOutline
                onClick={handleCloseAddModal}
                className="absolute top-4 right-4 text-2xl cursor-pointer text-gray-500 hover:text-red-500"
              />

              {/* Add Form */}
              <div className="text-2xl font-bold text-center mt-4 mb-4"></div>
              <form className="flex flex-col">
                {/* Course Name Input */}
                <div className="relative">
                  <input
                    type="text"
                    id="courseName"
                    name="courseName" // Make sure name is set here
                    placeholder=""
                    value={formData.courseName} // Control the value of the input using state
                    onChange={handleFormChange} // This will update the state on change
                    className="floating-input px-3 py-2 mt-2.5 text-[14px] max-w-full bg-white rounded-xl border border-solid border-black border-opacity-20 w-[314px]"
                    required
                  />
                  <label htmlFor="courseName" className="floating-label">
                    Course's Name
                  </label>

                  {/* Display Error for Course Name */}
                  {formErrors.courseName && (
                    <p className="text-red-500 text-sm mt-1">
                      {formErrors.courseName}
                    </p>
                  )}
                </div>
              </form>

              <div className="flex justify-center items-center mt-4">
                <button
                  onClick={handleAdd}
                  className="flex items-center rounded-md bg-[#2264E5] hover:bg-sky-500 py-2 px-4 border border-transparent text-center text-sm text-white transition-all shadow-sm hover:shadow-lg focus:bg-slate-700 focus:shadow-none  active:shadow-none disabled:pointer-events-none disabled:opacity-50 disabled:shadow-none"
                  type="button"
                >
                  <GiConfirmed className="mr-2" size={20} />
                  {loadingaddCourse ? "Confirming Order..." : "Confirm"}
                </button>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default CataloguePage;
