import React, { useCallback, useContext, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { IoIosArrowBack, IoIosArrowForward } from "react-icons/io";
import { CiSquareQuestion } from "react-icons/ci";
import { SiSession } from "react-icons/si";

import "../../index.css";
import { DarkModeContext } from "../../Context/DarkModeContext";
import axiosInstance from "../../Context/axiosInstance";
import SessionModal from "../../Components/SessionModal.jsx";
import { ImCheckmark } from "react-icons/im";

function StudentLiveRequest() {
  const { isDarkMode } = useContext(DarkModeContext);
  const navigate = useNavigate();
  const location = useLocation();

  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(20);
  const [searchQuery, setSearchQuery] = useState("");
  const [sortColumn, setSortColumn] = useState("StartDate");
  const [sortDirection, setSortDirection] = useState("desc");

  const [activeTab, setActiveTab] = useState("sessions");
  const [whatsapp, setWhatsapp] = useState("");
  const [description, setDescription] = useState("");
  const [sessions, setSessions] = useState([]);
  const [loading, setLoading] = useState(false);
  const [booking, setBooking] = useState(false);
  const [error, setError] = useState([]); // Ensure this is initialized as an array
  const [totalPages, setTotalPages] = useState(1);

  // RESERVE A SESSION
  const [modalOpen, setModalOpen] = useState(false);
  const [selectedSession, setSelectedSession] = useState(null);
  const [alert, setAlert] = useState({ message: "", type: "" }); // New alert state

  const handleRowClick = (session) => {
    setSelectedSession(session);
    setModalOpen(true);
  };

  // Calculate the progress based on filled inputs
  const filledInputs = [whatsapp, description].filter(Boolean).length;
  const progressPercentage = (filledInputs / 2) * 100;

  // GET Request: Fetching Sessions
  const fetchSessions = useCallback(async () => {
    setLoading(true);
    setError([]); // Reset errors

    try {
      const response = await axiosInstance.get(
        `/sessions?page=${page}&pageSize=${pageSize}&sortColumn=${sortColumn}&sortDirection=${sortDirection}&search=${encodeURIComponent(
          searchQuery
        )}`
      );

      setSessions(response.data.response);
      setTotalPages(Math.ceil(response.data.totalCount / pageSize));
    } catch (error) {
      if (error.response && error.response.status === 401) {
        // Handle unauthorized error (token expired or invalid)
      } else {
        setError([error.message || "An unexpected error occurred."]);
      }
    } finally {
      setLoading(false);
    }
  }, [page, pageSize, searchQuery, sortColumn, sortDirection]);

  useEffect(() => {
    fetchSessions();
  }, [fetchSessions]);

  useEffect(() => {
    setError([]);
    setWhatsapp("");
    setDescription("");
  }, [activeTab]);

  const handleReserve = async (whatsappNumber) => {
    if (!selectedSession) return;

    try {
      const response = await axiosInstance.post(
        `/sessions/${selectedSession.id}/reserve`,
        { whatsappNumber }
      );
      if (response.status === 200) {
        fetchSessions();
        setAlert({
          message: "Session reserved successfully!",
          type: "success",
        });
        setTimeout(() => setAlert({ message: "", type: "" }), 3000);
      }
    } catch (error) {
      if (error.response) {
        // Extract validation errors if present
        const apiErrors = error.response.data.errors || [];
        const errorMessages = apiErrors.map(
          (err) => `${err.name}: ${err.reason}`
        );
        setError(
          errorMessages.length > 0
            ? errorMessages
            : ["An unexpected error occurred."]
        );
      } else {
        setError(["An unexpected error occurred."]);
      }
    } finally {
      setModalOpen(false);
      setSelectedSession(null);
    }
  };

  const handleRequests = async () => {
    const isValidWhatsAppNumber = (number) => {
      const regex = /^\+?[0-9]\d{1,14}$/; // Matches international format
      return regex.test(number);
    };

    const trimmedWhatsApp = whatsapp.trim();
    const trimmedDescription = description.trim();

    // Check if WhatsApp number is empty
    if (!trimmedWhatsApp) {
      setError(["WhatsApp number cannot be empty."]);
      return; // Exit early if the number is empty
    }

    // Check if the WhatsApp number is valid
    if (!isValidWhatsAppNumber(trimmedWhatsApp)) {
      setError(["WhatsApp number is not valid."]);
      return; // Exit early if the number is invalid
    }

    // Check if the description is empty
    if (!trimmedDescription) {
      setError(["Description cannot be empty."]);
      return; // Exit early if the description is empty
    }
    // Check if the description is at least 6 characters long and contains no spaces
    if (trimmedDescription.length < 6) {
      setError(["Description must be at least 6 characters long"]);
      return; // Exit early if the description is invalid
    }

    const requestData = {
      whatsAppNumber: trimmedWhatsApp,
      description: trimmedDescription,
    };
    setBooking(true);

    try {
      await axiosInstance.post("/sessions/requests", requestData);
    } catch (error) {
      if (error.response) {
        const apiErrors = error.response.data.errors || [];
        const errorMessages = apiErrors.map((err) => `${err.reason}`);
        setError(
          errorMessages.length > 0
            ? errorMessages
            : ["An unexpected error occurred."]
        );
      } else {
        setError(["An unexpected error occurred."]);
      }
    } finally {
      setBooking(false);
      setAlert({
        message:
          "Your session request has been sent. Thank you very much for writing to us.",
        type: "success",
      });
      setTimeout(() => setAlert({ message: "", type: "" }), 5000);
      setError([]); // Clear previous errors
      setWhatsapp("");
      setDescription("");
    }
  };

  const goToNextPage = () => {
    navigate(+1);
  };

  const goToPreviousPage = () => {
    navigate(-1);
  };

  const formattedPath = `${location.pathname
    .slice(1)
    .replace(/[_-]/g, " ")
    .replace(/\b\w/g, (char) => char.toUpperCase())
    .replace(/\//g, " > ")}`;

  const handlePageChange = (newPage) => {
    setPage(newPage);
    navigate(`?page=${newPage}&pageSize=${pageSize}`);
  };

  const handlePageSizeChange = (e) => {
    const newSize = Number(e.target.value);
    setPageSize(newSize);
    setPage(1);
    navigate(`?page=1&pageSize=${newSize}`);
  };

  const toggleSortDirection = (column) => {
    if (sortColumn === column) {
      setSortDirection((prev) => (prev === "asc" ? "desc" : "asc"));
    } else {
      setSortColumn(column);
      setSortDirection("asc");
    }
  };

  return (
    <div
      className={`flex flex-col lg:flex-row lg:items-start ${
        isDarkMode ? "bg-[#181818] text-black" : "bg-customBlue text-black"
      } p-4 lg:p-8 min-h-[200vh] md:min-h-screen flex-1`}
    >
      <div
        className={`${
          isDarkMode ? "bg-[#1e1e1e] text-white" : "bg-white text-black"
        } p-4 lg:p-8 mt-[40px] lg:mt-0 rounded-lg shadow-xl flex-1`}
      >
        {/* Alert Message */}
        {alert.message && (
          <div
            className={`alert ${
              alert.type === "success" ? "alert-success" : "alert-error"
            }`}
          >
            {alert.message}
          </div>
        )}
        {/* PATH AND CONTROLS */}
        <div className="lg:flex w-full justify-between items-center mb-4 text-gray-700 text-sm capitalize">
          <div className="flex gap-1 items-center">
            <button
              onClick={goToPreviousPage}
              className="w-[24px] h-[20px] flex justify-center items-center bg-gray-300 rounded-[6px] disabled:opacity-50"
            >
              <IoIosArrowBack />
            </button>
            <button
              onClick={goToNextPage}
              className="w-[24px] h-[20px] flex justify-center items-center bg-gray-300 rounded-[6px] disabled:opacity-50"
            >
              <IoIosArrowForward />
            </button>
            <div
              className={`${
                isDarkMode ? "text-white" : "text-black"
              } text-sm capitalize px-2 py-4`}
            >
              <span>{formattedPath}</span>
            </div>
          </div>

          <div className="flex flex-col lg:flex-row lg:justify-between lg:w-[320px] p-3">
            <input
              type="text"
              placeholder="Search"
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
              className={`${activeTab === "calendar-time" && "hidden"} ${
                isDarkMode
                  ? "bg-[#292929] text-white"
                  : "bg-gray-100 text-black"
              } px-4 py-2 border border-gray-300 rounded-lg w-full`}
            />
          </div>

          <div className="flex flex-col lg:flex-row lg:gap-4">
            <button
              onClick={() => setActiveTab("sessions")}
              className="flex lg:hover:scale-110 transition-transform ease-in-out duration-300 items-center mb-2 lg:mb-0 px-4 py-2 lg:px-6 lg:py-3 justify-center bg-[#2264E5] text-white rounded-lg"
            >
              <SiSession size={30} className="pr-2" />
              Available Sessions
            </button>

            <button
              onClick={() => setActiveTab("calendar-time")}
              className="flex lg:hover:scale-110 transition-transform ease-in-out duration-300 items-center mb-2 lg:mb-0 px-4 py-2 lg:px-6 lg:py-3 justify-center bg-red-500 text-white rounded-lg"
            >
              <CiSquareQuestion size={30} className="pr-2" />
              Request A Date
            </button>
          </div>
        </div>

        {activeTab === "sessions" && (
          <table className="min-w-full mb-4">
            <thead
              className={`${
                isDarkMode
                  ? "bg-[#1e1e1e]  text-gray-300"
                  : "bg-white text-black"
              }`}
            >
              <tr>
                <th
                  onClick={() => toggleSortDirection("Name")}
                  className="cursor-pointer px-4 py-2 text-center text-base font-medium uppercase tracking-wider"
                >
                  Name
                  {sortColumn === "Name" && (
                    <span className="text-sm px-1 items-center text-blue-400">
                      {sortDirection === "asc" ? "▲" : "▼"}
                    </span>
                  )}
                </th>
                <th
                  onClick={() => toggleSortDirection("StartDate")}
                  className="cursor-pointer px-4 py-2 text-center text-base font-medium uppercase tracking-wider"
                >
                  Start Date
                  {sortColumn === "StartDate" && (
                    <span className="text-sm px-1 items-center text-blue-400">
                      {sortDirection === "asc" ? "▲" : "▼"}
                    </span>
                  )}
                </th>
                <th className="px-4 py-2 text-center text-base font-medium uppercase tracking-wider">
                  Reserved
                </th>
              </tr>
            </thead>
            <tbody>
              {sessions.length > 0 &&
                sessions.map((session) => (
                  <tr
                    key={session.id}
                    className={`transition-all duration-200 ${
                      isDarkMode
                        ? "hover:bg-gray-800 rounded-[10px]"
                        : "hover:bg-gray-200 rounded-[10px]"
                    }`}
                  >
                    <td className="px-4 py-2 whitespace-nowrap text-base text-center">
                      {session.name}
                    </td>
                    <td className="px-4 py-2 whitespace-nowrap text-base text-center text-[#2083D8]">
                      {new Date(session.startDate).toLocaleString("en-EG", {
                        timeZone: "Africa/Cairo",
                      })}
                    </td>
                    <td
                      className={`flex justify-center items-center h-16 px-4 py-2 whitespace-nowrap text-base`}
                    >
                      {session.isReserved === false ? (
                        <button
                          onClick={() => handleRowClick(session)}
                          type="button"
                          class={`${
                            isDarkMode
                              ? "border-2 border-customRed hover:bg-customRed hover:scale-105 ease-in-out duration-300 text-white"
                              : "bg-sky-700 hover:bg-sky-500 hover:scale-105 ease-in-out duration-300 text-white"
                          } px-4 py-2 rounded-[5px]`}
                        >
                          Reserve
                        </button>
                      ) : (
                        <ImCheckmark
                          size={20}
                          className={`${
                            isDarkMode ? "text-green-400" : "text-green-600"
                          }`}
                        />
                      )}
                    </td>
                  </tr>
                ))}
              {!sessions.length && !loading && (
                <tr className="h-[650px]">
                  <td
                    colSpan="7"
                    className={`${
                      isDarkMode ? "text-white" : "text-gray-500"
                    } text-center text-lg text-gray-500`}
                  >
                    No Sessions found
                  </td>
                </tr>
              )}
              {loading && (
                <tr>
                  <td
                    colSpan="6"
                    className={`${
                      isDarkMode ? "text-white" : "text-gray-500"
                    } px-2 py-5 text-center text-lg`}
                  >
                    <div className="flex justify-center items-center">
                      <div className="loader">
                        <div className="dot"></div>
                        <div className="dot"></div>
                        <div className="dot"></div>
                      </div>
                    </div>
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        )}

        <SessionModal
          isOpen={modalOpen}
          onClose={() => setModalOpen(false)}
          onConfirm={handleReserve}
          sessionDetails={selectedSession}
          errors={error}
        />

        {activeTab === "calendar-time" && (
          <div
            className={`${
              isDarkMode ? "text-white" : "text-gray-700"
            } mt-4 flex flex-col items-center`}
          >
            {/* Progress Bar */}
            <div className="w-full mb-4">
              <div className="h-2 bg-gray-300 rounded">
                <div
                  className="h-full bg-blue-600 rounded transition-all duration-300 ease-in-out"
                  style={{ width: `${progressPercentage}%` }}
                />
              </div>
            </div>
            <div className="mb-4 w-full max-w-md">
              <label className="block mb-1 font-semibold">
                WhatsApp Number:
              </label>
              <input
                type="text"
                value={whatsapp}
                onChange={(e) => setWhatsapp(e.target.value)}
                className={`text-black border border-gray-300 rounded p-2 w-full shadow-sm focus:outline-none focus:ring focus:ring-blue-300`}
                placeholder="Enter your WhatsApp number"
              />
            </div>
            <div className="mb-4 w-full max-w-md">
              <label className="block mb-1 font-semibold">Description:</label>
              <textarea
                value={description}
                onChange={(e) => setDescription(e.target.value)}
                className={`text-black border border-gray-300 min-h-[100px] rounded p-2 w-full shadow-sm focus:outline-none focus:ring focus:ring-blue-300`}
                placeholder="Enter a description"
              />
            </div>
            {error.length > 0 && (
              <div className="error-message">
                {error.map((msg, index) => (
                  <p key={index} className="text-red-600">
                    {msg}
                  </p>
                ))}
              </div>
            )}
            <button
              onClick={handleRequests}
              className={`${
                isDarkMode
                  ? "bg-red-600 text-white hover:bg-red-700 focus:outline-none focus:ring focus:ring-red-300"
                  : "bg-blue-600 text-white hover:bg-blue-700 focus:outline-none focus:ring focus:ring-blue-300"
              } mt-4 rounded-[25px] p-2 w-full max-w-md shadow`}
            >
              {booking ? "Booking..." : "Book"}
            </button>
          </div>
        )}
        <div
          className={`${
            activeTab === "calendar-time" && "hidden"
          } flex items-center justify-end py-4 text-black`}
        >
          <label
            htmlFor="pageSize"
            className={`${
              isDarkMode ? "text-white" : "text-gray-700"
            } mr-2 text-sm`}
          >
            Sessions per page:
          </label>
          <select
            id="pageSize"
            value={pageSize}
            onChange={handlePageSizeChange}
            className="px-4 py-2 border border-gray-300 rounded-lg"
          >
            {[20, 30, 50].map((size) => (
              <option key={size} value={size}>
                {size}
              </option>
            ))}
          </select>
        </div>
        {/* Pagination Controls (Bottom) */}
        <div
          className={`${
            activeTab === "calendar-time" && "hidden"
          } flex justify-center pt-8 text-white`}
        >
          <div>
            {Array.from({ length: totalPages }, (_, index) => (
              <button
                key={index + 1}
                onClick={() => handlePageChange(index + 1)}
                className={`px-4 py-2 ${
                  page === index + 1
                    ? isDarkMode
                      ? "bg-customRed"
                      : "bg-customBlue"
                    : isDarkMode
                    ? "bg-gray-300 text-black"
                    : "bg-gray-300"
                } rounded-[5px] mx-1 my-1`}
              >
                {index + 1}
              </button>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}

export default StudentLiveRequest;
