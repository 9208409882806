import React, { useCallback, useContext, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { ClipLoader } from "react-spinners";
import {
  IoIosArrowBack,
  IoIosArrowForward,
  IoMdTrash,
  IoMdCreate,
} from "react-icons/io";
import { IoCloseCircleOutline } from "react-icons/io5";
import { MdDelete } from "react-icons/md";

import { DarkModeContext } from "../../../Context/DarkModeContext.jsx";
import axiosInstance from "../../../Context/axiosInstance.js";
import EditAnnouncementForm from "./EditAnnouncementForm.jsx";

function AdminAnnouncements() {
  const { isDarkMode } = useContext(DarkModeContext);

  // FOR COURSE FETCHING
  const [announcements, setAnnouncements] = useState([]);
  const [loading, setLoading] = useState(false);

  const [searchQueryForCourses, setSearchQueryForCourses] = useState("");

  // FOR ANNOUNCEMENTS FETCHING
  const [courses, setCourses] = useState([]);
  const [loadingCourses, setLoadingCourses] = useState(false);
  const [totalPages, setTotalPages] = useState(1);
  const [pageForAnnouncements, setPageForAnnouncements] = useState(1);
  const pageSizeForAnnouncements = 5;
  const [searchQueryForAnnouncements, setSearchQueryForAnnouncements] =
    useState("");

  // FOR ANNOUNCEMENT ADD
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [selectedCourses, setSelectedCourses] = useState([]);
  const [newTitle, setNewTitle] = useState("");
  const [newDescription, setNewDescription] = useState("");
  const [loadingAdd, setLoadingAdd] = useState(false);

  // FOR ANNOUNCEMENT EDIT
  const [filteredCourses, setFilteredCourses] = useState(courses);
  const [editingAnnouncementId, setEditingAnnouncementId] = useState(null);

  // FOR ANNOUNCEMENT DELETE
  const [isDeleting, setIsDeleting] = useState(false);
  const [isDeletingLoading, setIsDeletingLoading] = useState(false);
  const [showModalDelete, setShowModalDelete] = useState(false);
  const [announcementToDelete, setAnnouncementToDelete] = useState(null);

  const location = useLocation();
  const navigate = useNavigate();

  // Fetch courses and announcements (including sorting, pagination, and search)
  const fetchCourses = useCallback(async () => {
    setLoadingCourses(true);
    try {
      const response = await axiosInstance.get(
        `/courses?page=1&pageSize=99999&sortColumn=Name&sortDirection=asc&search=${encodeURIComponent(
          searchQueryForCourses
        )}`
      );
      setCourses(response.data.response);
      setFilteredCourses(response.data.response);
    } catch (error) {
      // toast.error("Error fetching courses");
    } finally {
      setLoadingCourses(false);
    }
  }, [searchQueryForCourses]);

  useEffect(() => {
    fetchCourses();
  }, [fetchCourses]);

  const fetchAnnouncements = useCallback(
    async (pageForAnnouncements) => {
      setLoading(true);
      try {
        const response = await axiosInstance.get(
          `/announcements?page=${pageForAnnouncements}&pageSize=${pageSizeForAnnouncements}&sortColumn=CreatedOnUtc&search=${encodeURIComponent(
            searchQueryForAnnouncements
          )}`
        );
        setAnnouncements(response.data.response);
        const totalPages = Math.ceil(
          response.data.totalCount / pageSizeForAnnouncements
        );
        setTotalPages(totalPages);
      } catch (error) {
        // toast.error("Error fetching announcements");
      } finally {
        setLoading(false);
      }
    },
    [searchQueryForAnnouncements]
  );

  useEffect(() => {
    fetchAnnouncements(pageForAnnouncements);
  }, [fetchAnnouncements, pageForAnnouncements]);

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const urlPage = queryParams.get("pageForAnnouncements");
    const pageNum = urlPage ? parseInt(urlPage, 10) : 1;
    setPageForAnnouncements(pageNum);
  }, [location]);

  useEffect(() => {
    // Reset to page 1 whenever the search query changes
    setPageForAnnouncements(1);
  }, [searchQueryForAnnouncements]);

  const openAddModal = () => {
    setShowModal(true);
    setIsModalOpen(true);
  };

  const closeAddModal = () => {
    setShowModal(false);
    setNewTitle("");
    setNewDescription("");
    setSelectedCourses([]);
    setTimeout(() => {
      setIsModalOpen(false);
    }, 500);
  };

  const addAnnouncement = async () => {
    if (newTitle.trim() === "" || newDescription.trim() === "") {
      toast.error("Both Title and Description must be filled.");
      return;
    }
    if (selectedCourses.length === 0) {
      toast.error("At least one course must be selected.");
      return;
    }

    try {
      setLoadingAdd(true);
      const response = await axiosInstance.post("/announcements", {
        title: newTitle,
        description: newDescription,
        coursesIds: selectedCourses,
      });
      setAnnouncements([...announcements, response.data]);
      fetchAnnouncements(pageForAnnouncements);
      closeAddModal();
      toast.success("Announcement added successfully!");
    } catch (error) {
      toast.error("Error adding announcement.");
    } finally {
      setLoadingAdd(false);
    }
  };

  const deleteAnnouncement = async (id) => {
    setIsDeletingLoading(true);
    try {
      await axiosInstance.delete(`/announcements/${id}`);
      fetchAnnouncements(pageForAnnouncements);
      setIsDeleting(false);
      setAnnouncementToDelete(null);
      toast.success("Announcement deleted successfully!");
    } catch (error) {
      toast.error("Error deleting announcement.");
    } finally {
      setIsDeletingLoading(false);
    }
  };

  const confirmDelete = (announcement) => {
    setShowModalDelete(true);
    setAnnouncementToDelete(announcement);
    setIsDeleting(true);
  };

  const cancelDelete = () => {
    setShowModalDelete(false);
    setAnnouncementToDelete(null);
    setTimeout(() => {
      setIsDeleting(false);
    }, 500);
  };

  // Open the edit form with the selected announcementId
  const openEditForm = (announcementId) => {
    setEditingAnnouncementId(announcementId);
  };

  const closeEditForm = () => {
    setEditingAnnouncementId(null);
    fetchAnnouncements(pageForAnnouncements);
  };

  const handleCheckboxChange = (courseId) => {
    setSelectedCourses((prevSelectedCourses) => {
      const newSelectedCourses = prevSelectedCourses.includes(courseId)
        ? prevSelectedCourses.filter((id) => id !== courseId) // Deselect course
        : [...prevSelectedCourses, courseId]; // Select course

      return newSelectedCourses;
    });
  };

  const goToNextPage = () => {
    if (pageForAnnouncements < totalPages) {
      navigate(`?pageForAnnouncements=${pageForAnnouncements + 1}`);
    }
  };

  const goToPreviousPage = () => {
    if (pageForAnnouncements > 1) {
      navigate(`?pageForAnnouncements=${pageForAnnouncements - 1}`);
    }
  };

  return (
    <div
      className={`flex justify-center items-center min-h-screen px-4 sm:px-6 lg:px-8 ${
        isDarkMode ? "bg-[#2c2929]" : "bg-gray-100"
      }`}
    >
      <div
        className={`${
          isDarkMode
            ? "bg-[#1e1e1e] border border-gray-500 text-white"
            : "bg-white text-black"
        } p-8 lg:px-6 lg:py-4 rounded-lg shadow-xl w-full max-w-3xl overflow-hidden`}
      >
        {/* Course Info and Announcement Count */}
        <div className={`text-black mb-6 text-center rounded-full p-4`}>
          <input
            type="text"
            placeholder="Look for a Title..."
            value={searchQueryForAnnouncements}
            onChange={(e) => setSearchQueryForAnnouncements(e.target.value)}
            className={`w-full border border-blue-100 px-4 py-2 rounded-full`}
          />
        </div>

        {/* Pagination Controls */}
        <div className="flex justify-between items-center pb-6">
          <button
            onClick={goToPreviousPage}
            disabled={pageForAnnouncements === 1}
            className="px-4 py-2 bg-blue-600 text-white rounded-lg disabled:opacity-50"
          >
            <IoIosArrowBack />
          </button>

          <span className="text-sm">
            Page {pageForAnnouncements} of {totalPages}
          </span>

          <button
            onClick={goToNextPage}
            disabled={pageForAnnouncements >= totalPages} // Disable if we are on the last page
            className="px-4 py-2 bg-blue-600 text-white rounded-lg disabled:opacity-50"
          >
            <IoIosArrowForward />
          </button>
        </div>

        {/* Add Announcement Modal */}
        {isModalOpen && (
          <div
            className={`fixed inset-0 bg-gray-800 bg-opacity-50 z-20 flex justify-center items-center`}
          >
            <div
              className={`${showModal ? "slide-in" : "slide-out"} ${
                isDarkMode ? "bg-[#292929]" : "bg-white"
              } p-6 rounded-lg shadow-lg w-[500px] lg:ml-[270px]`}
            >
              <div className="flex justify-between items-center mb-4">
                <h2 className="text-xl font-semibold">
                  Create A New Announcement
                </h2>
                <button
                  onClick={closeAddModal}
                  className="text-xl text-gray-500 hover:text-red-500 ease-in-out transition-all duration-300"
                >
                  <IoCloseCircleOutline size={30} />
                </button>
              </div>

              {/* Announcement Form */}
              <div className="pb-4">
                <input
                  type="text"
                  value={newTitle}
                  onChange={(e) => setNewTitle(e.target.value)}
                  placeholder="Title"
                  className={`text-black w-full p-4 bg-gray-100 border border-gray-300 rounded-lg mb-4`}
                />
                <textarea
                  value={newDescription}
                  onChange={(e) => setNewDescription(e.target.value)}
                  placeholder="Description"
                  className="text-black w-full min-h-[60px] max-h-[200px] p-4 bg-gray-100 border border-gray-300 rounded-lg"
                  rows="4"
                />
              </div>

              {/* Course Selection */}
              <div className="max-h-[300px] overflow-y-auto p-3 border-2 border-gray-300 rounded-md custom-scrollbar">
                <div className="flex flex-col lg:flex-row lg:justify-between pb-2">
                  <input
                    type="text"
                    placeholder="Search"
                    value={searchQueryForCourses}
                    onChange={(e) => setSearchQueryForCourses(e.target.value)}
                    className={`${
                      isDarkMode
                        ? "bg-[#292929] border border-gray-600"
                        : "bg-gray-100 border border-gray-300"
                    } w-full px-4 py-2 rounded-lg`}
                  />
                </div>
                {filteredCourses.length > 0 ? (
                  filteredCourses.map((course) => (
                    <div
                      key={course.id}
                      className="flex items-center gap-2 p-1"
                    >
                      <input
                        type="checkbox"
                        id={`course-${course.id}`}
                        onChange={() => handleCheckboxChange(course.id)}
                      />
                      <span className="text-sm">
                        {course.name}{" "}
                        {course.gradeLevel === "Custom" && (
                          <span className="text-red-500">
                            * Custom Course *
                          </span>
                        )}
                      </span>
                    </div>
                  ))
                ) : loadingCourses ? (
                  <div className="absolute ml-[250px] inset-0 flex justify-center items-center bg-opacity-50 z-10">
                    <ClipLoader color="white" size={50} />
                  </div>
                ) : (
                  <div className="text-gray-500">No courses found</div>
                )}
              </div>

              {/* Add/Save Buttons */}
              <div className="flex justify-center gap-4 mt-4">
                <button
                  onClick={addAnnouncement}
                  className="px-6 py-2 bg-blue-600 text-white rounded-lg"
                >
                  {loadingAdd ? "Posting..." : "Post"}
                </button>
              </div>
            </div>
          </div>
        )}

        {/* Announcements List */}
        <div className="min-h-[730px]">
          {/* Loading Spinner */}
          {loading ? (
            <div className="absolute ml-[250px] inset-0 flex justify-center items-center bg-opacity-50 z-10">
              <ClipLoader color="#2264E5" size={50} />
            </div>
          ) : announcements.length > 0 ? (
            announcements.map((announcement) => (
              <div
                key={announcement.id}
                className={`${
                  isDarkMode
                    ? "border border-grey-600 text-white"
                    : "bg-gray-50 text-gray-900"
                } p-4 rounded-lg shadow-md mb-4`}
              >
                <h3 className={`text-lg font-semibold max-w-[650px] truncate`}>
                  {announcement.title}
                </h3>
                <p
                  className={`${
                    isDarkMode ? "text-gray-400" : "text-gray-600"
                  } text-sm max-w-[650px] truncate`}
                >
                  {announcement.description}
                </p>

                {/* Display all course names from the 'courses' array */}
                {announcement.courses && announcement.courses.length > 0 ? (
                  <p className={` text-sm pt-3`}>
                    Posted in:{" "}
                    {announcement.courses.map((course, index) => (
                      <span
                        className={`${
                          isDarkMode ? "text-blue-400" : "text-green-600"
                        }`}
                        key={course.id}
                      >
                        {course.name}
                        {index < announcement.courses.length - 1 ? ", " : ""}
                      </span>
                    ))}
                  </p>
                ) : (
                  <p className="text-sm">Courses: Not available</p>
                )}

                <div className={`flex justify-end gap-2`}>
                  <IoMdCreate
                    className={`${
                      isDarkMode
                        ? "hover:text-blue-400"
                        : "hover:text-green-400"
                    } cursor-pointer ease-in-out transition-all duration-300`}
                    onClick={() => openEditForm(announcement.id)}
                  />
                  <IoMdTrash
                    className={`${
                      isDarkMode ? "hover:text-red-600" : "hover:text-red-600"
                    } cursor-pointer ease-in-out transition-all duration-300`}
                    onClick={() => confirmDelete(announcement)}
                  />
                </div>
              </div>
            ))
          ) : (
            <div className="min-h-[730px] flex justify-center items-center">
              <p className="w-full text-center">No announcements available</p>
            </div>
          )}
        </div>
        <div className="w-[500px] flex justify-center mx-auto border-b border-gray-400 pb-4 mb-3"></div>
        <div className="w-full flex justify-center">
          <button
            onClick={openAddModal}
            className={`${
              isDarkMode ? "bg-blue-600" : "bg-blue-600"
            } px-6 py-2 text-white rounded-lg hover:bg-blue-500 ease-in-out transition-all duration-300`}
          >
            Add Announcement
          </button>
        </div>
        {/* Delete Confirmation */}
        {isDeleting && (
          <div className="fixed inset-0 flex justify-center items-center bg-gray-800 bg-opacity-50 z-20">
            <div
              className={`${showModalDelete ? "slide-in" : "slide-out"} ${
                isDarkMode ? "bg-[#292929]" : "bg-white"
              } p-8 rounded-lg shadow-lg text-center relative`}
            >
              <div className="flex justify-center pb-4">
                <MdDelete className="text-customRed justify-center" size={50} />
              </div>

              <p>Are you sure you want to delete this announcement?</p>
              <div className="flex justify-center gap-4 mt-4">
                <button
                  onClick={() => deleteAnnouncement(announcementToDelete.id)}
                  className="px-6 py-2 bg-red-600 text-white rounded-lg"
                >
                  {isDeletingLoading ? "Deleting..." : "Yes"}
                </button>
                <button
                  onClick={cancelDelete}
                  className="px-6 py-2 bg-gray-600 text-white rounded-lg"
                >
                  Cancel
                </button>
              </div>
            </div>
          </div>
        )}
      </div>

      {/* Conditionally Render the Edit Form */}
      {editingAnnouncementId && (
        <EditAnnouncementForm
          announcementId={editingAnnouncementId}
          onClose={closeEditForm}
        />
      )}

      <ToastContainer />
    </div>
  );
}

export default AdminAnnouncements;
